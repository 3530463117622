const toastStyles = {
    default: ({
        palette: { success },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
    }) => ({
        "& .MuiPaper-root": {
            backgroundColor: success.main,
            borderRadius: borderRadius.lg,
            fontSize: size.md,
            fontWeight: 400,
            boxShadow: lg,
            px: 2,
            py: 0.5,
        },
    }),
    success: ({
        palette: { success },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
    }) => ({
        "& .MuiPaper-root": {
            backgroundColor: success.main,
            borderRadius: borderRadius.lg,
            fontSize: size.md,
            fontWeight: 400,
            boxShadow: lg,
            px: 2,
            py: 0.5,
        },
    }),
    error: ({
        palette: { error },
        borders: { borderRadius },
        typography: { size },
        boxShadows: { lg },
    }) => ({
        "& .MuiPaper-root": {
            backgroundColor: error.main,
            borderRadius: borderRadius.lg,
            fontSize: size.md,
            fontWeight: 600,
            boxShadow: lg,
            px: 2,
            py: 0.5,
        },
    })
};

export default toastStyles;