import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function SemneVizitaStoma() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Semne că ar trebui să merg la stomatolog" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item sx={{ whiteSpace: "normal" }}>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" >
                        <br />Sângerarea gingiilor în timpul igienizării
                        <br /><br />Creșterea sensibilității la cald, rece, dulce
                        <br /><br />Apariția unui gust ciudat sau respirație urât mirositoare
                        <br /><br />Dificultate la înghițire
                        <br /><br />Desprinderea gingiilor de dinte
                        <br /><br />Orice fel de durere
                        <br /><br />Leziuni intraorale prezente de mai mult de 2 săptămâni
                        <br /><br />Dinții încep să aibă o mobilitate mai mare
                        <br /><br />Recomandăm că vizitele la stomatolog să se facă cel puțin o dată pe an înainte că orice simptom să apară
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default SemneVizitaStoma;