// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// import { useTheme } from '@material-ui/core/styles';
//const theme = useTheme();
//const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
import ArrowRight from '@mui/icons-material/ArrowRight';

function HorizontalResponsiveCard({ image, name, actions, description, imageMt }) {

    // background: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
    //`${linearGradient(
    //    rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
    //    rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
    //)}`,
    // pentru text style={{ fontSize: "4vw" }} 
    const actionsRendered = actions.map(({ route, label }, n) => (
        <MKButton key={n}
            component={Link}
            to={route}
            variant="gradient"
            size="medium"
            color="info"
            sx={{ marginRight: 1.2, marginBottom: 1.2, width: { xs: "100%", lg: "auto" } }}

        >
            {label}
            < ArrowRight sx={{ fontWeight: "bold" }} />
        </MKButton >

    ));
    return (
        // or Card
        <Card sx={{
            mt: 3,

        }}>
            <Grid container>
                <Grid item xs={12} md={6} lg={4} sx={{ mt: imageMt }}>
                    <MKBox width="100%" pt={2} pb={1} px={2} >
                        <MKBox
                            component="img"
                            src={image}
                            alt={name}
                            width="100%"
                            borderRadius="md"
                            shadow="lg"
                        />
                    </MKBox>
                </Grid>
                <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
                    <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                        <MKBox>
                            <MKTypography variant="h3" pb={1} >{name}</MKTypography>
                            <MKTypography variant="body1" color="text">
                                {description}
                            </MKTypography>
                        </MKBox>
                        <MKBox mt={4}>
                            {actionsRendered}
                        </MKBox>
                    </MKBox>

                </Grid>
            </Grid>
        </Card >
    );
}

// Typechecking props for the HorizontalResponsiveCard
HorizontalResponsiveCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default HorizontalResponsiveCard;
