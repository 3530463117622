
import pedo from "assets/images/services/pedo.jpg";
import orto from "assets/images/services/orto.jpg";
import endo from "assets/images/services/s_endo.jpg";
import prote from "assets/images/services/prote.jpg";
import s_stoma_gen from "assets/images/services/s_stoma_gen.jpg";
import estetica from "assets/images/services/s_estetica.jpg";
import implanto from "assets/images/services/implanto.jpg";
import paro from "assets/images/services/paro.jpg";

const servicesData = {
    description: "Services presentation",
    items: [
        {
            image: s_stoma_gen,
            title: "Stomatologie Generala",
            description: "Igienizare profesională / Obturații (plombe) etc",
            icon: "",
            route: "/test",
            key: "stoma_generala"
        },
        {
            image: prote,
            title: "Protetică",
            description: "Proteze totale / Coroane și lucrări fixe etc",
            icon: "",
            route: "/test",
            key: "protetica"
        },
        {
            image: endo,
            title: "Endodonție",
            description: "Tratamente de canal pentru salvarea dintelui natural.",
            icon: "",
            route: "/test",
            key: "endo"
        }, {
            image: estetica,
            title: "Estetică Dentară",
            description: "Redarea aspectului similar dintelui natural.",
            icon: "",
            route: "/test",
            key: "estetica_dentara"
        },
        {
            image: implanto,
            title: "Implantologie",
            description: "Interventii chirurgicale pentru redobândirea functionalității și aspectului natural",
            icon: "",
            route: "/test",
            key: "implanto"
        },
        {
            image: orto,
            title: "Ortodonție",
            description: "Aparate dentare pentru redobândirea funcționalității și esteticii dinților.",
            icon: "",
            route: "/test",
            key: "orto"
        },
        {
            image: paro,
            title: "Parodontologie",
            description: "Tratarea bolii parodontale prin tehnici minim invazive.",
            icon: "",
            route: "/test",
            key: "paro"
        },
        {
            image: pedo,
            title: "Pedodonție",
            description: "Tratamente dentare și acomodarea copiilor cu vizitele la stomatolog.",
            icon: "",
            route: "/test",
            key: "pedo"
        },
    ]
};

export default servicesData;