import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function GhidProtezeTotale() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 0 }}>
            <DefaultSectionTitle title="Ghid acomodare proteze totale" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item sx={{ whiteSpace: "normal" }} textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <br /><b>Proteza totală</b> este ceva cu totul necunoscut pentru pacient dacă nu a mai purtat
                        iar adaptarea cu aceasta poate fi un proces anevoios și de durată.
                        Primul lucru pe care îl cerem de la pacient este dorința de avea o proteza și mai ales motivația
                        de a se acomoda cu ea. De obicei perioada de <b>acomodare</b> este de <b>2 săptămâni</b>.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        În momentul începerii tratamentului este important că pacientul să știe la ce se aștepte.
                        Senzația protezei în gura nu se aseamănă deloc cu senzația când erau dinții prezenți.
                        Pacientul va putea vorbi foarte bine cu protezele, va putea să mestece dar nu va mai putea să
                        muște spre exemplu dintr-un măr, alimentele trebuind tăiate înainte.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Este normal ca proteza să jeneze iniţial şi să apese anumite zone, iar acolo pot apărea inflamații
                        și durerei. De aceea, pacientul este rechemat următoarea zi pentru rezolvarea acestor mici probleme.
                        Se întâmplă ca pacientul să revină de câteva ori până când proteza va fi complet adaptată.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Se recomandă ca <b>protezele</b> să fie <b>curăţate de două ori pe zi</b>, de preferinţă seara înainte de culcare,
                        <b> cu apă şi săpun lichid şi o perie separată</b>, special folosită pentru proteză. Nu este indicată pasta
                        de dinţi în curătarea protezei fiindcă, în timp, duce la pierderea luciului şi retenţionarea mai
                        uşoară a plăcii bacteriene şi  pe suprafaţa acesteia.
                        <br />În plus, de două ori pe săptămână recomandăm a se lăsa peste noapte proteza într-un recipient
                        cu o substanţă efervescentă dezinfectanta special creată pentru proteza dentară
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <br /><b>Acomodarea cu proteza dentară</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Pentru majoritatea durează câteva săptămâni, pentru unii poate dura chiar și luni până când protezele se simt
                        mai confortabile. De obicei, medicul dentist se asigură că nu există probleme cu adaptarea pe câmpul protetic
                        precum și cu construcția protezei, o ajustează dacă este nevoie, dar dvs. trebuie să perseverați cu purtarea lor.
                        Uneori poate fi puțin descurajant, dar vă veți obișnui cu senzația și aspectul lor.
                        <br /> Este important să înțelegeți pe deplin că purtarea protezelor dentare, mâncatul și vorbitul cu ele este foarte diferit față de cum era cu dinții normali.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <br /><b>În primele zile, este posibil ca protezele dvs. să vă provoace unele disconforturi</b>:
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Exces de salivă;</b>

                        <br /><br /> <b>Greață</b>: durează în mod normal de la câteva ore până la câteva zile (consultați un profesionist dacă simțiți această stare mai mult de o săptămână; proteza poate necesita o ușoară ajustare);

                        <br /><br /> <b>Impresia că proteza este prea mare</b> și că împinge buzele înainte;

                        <br /><br />  <b>Durere</b>: când s-au extras dinții, este posibil să simțiți dureri cu protezele până la vindecarea totală a gingiilor (6-8 săptămâni);

                        <br /> <br /><b>Disconfort</b> la nivelul protezei inferioare: provoacă mai mult disconfort decât proteza superioară deoarece mișcarea constantă a limbii contribuie la instabilitatea acesteia

                        <br /> <br /> <b>Dificultate în a pronunța unele cuvinte</b>. Majoritatea oamenilor se adaptează destul de repede, în câteva săptămâni. Anumite sunete, care necesită controlul buzelor și al limbii în raport cu dinții din față, tind să fie o problemă, dar practicarea vorbirii și a citirii cu voce tare va ajuta la depășirea acestei dificultăți.
                        <br /> <br /><b>Dificultate de a mesteca unele alimente</b>. Mâncatul vă necesita puțină practică. Începeți cu alimente moi și tăiați-le în bucăți mici. Odată ce vă obișnuiți să mestecați, introduceți mai mult din mâncarea dvs. normală. Luați lucrurile încet, vă dura timp să vă adaptați.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Nu este neobișnuit să apară zone inflamate după purtarea noii proteze pentru o perioadă de timp. Când medicul ajustează proteza, vă încerca să elimine zonele care se freacă, însă aceste zone devin evidente decât după câteva zile. Adesea, mucoasa se vă adapta și „se vă întări”, cu toate acestea, uneori este necesară ajustarea.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Majoritatea efectelor secundare ale protezei mobile vor dispărea după câteva zile. Pe de altă parte, dacă dinții naturali sunt sensibili sau țesuturile sunt iritate, este important să consultați medicul dentist pentru a vă ajusta protezele dentare.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Un <b>adeziv pentru proteză ajută</b> la menținerea acesteia în poziția corectă. Poate fi utilizat la începutul perioadei de tranziție și ajustare (de exemplu, dacă gingiile sunt dureroase). Utilizarea unui adeziv are două avantaje principale: se evită depunerea particulelor de alimente între proteză și gingie și se limitează iritația și disconfortul la nivelul gingiilor.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Pentru a păstra proteza mobilă un timp cât mai îndelungat, este important să o igienizăm corect și să o îndepărtăm pe timpul nopții
                        pentru a lăsă mucoasa gingivală liberă. După fiecare masă, dacă este posibil, se recomandă îndepărtarea protezei și curțarea sa
                        de resturile alimentare, precum și clătirea gurii. Cu cât purtați protezele mai mult, cu atât vă veți obișnui mai mult cu ele și cu atât mai naturale vor începe să se simtă.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default GhidProtezeTotale;