import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";

// About Us page sections
import Information from "pages/AboutUs/sections/Information";
import Team from "pages/AboutUs/sections/Team";
import Gallery from "pages/AboutUs/sections/Gallery";
import SectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import routes from "routes";

// Images
import bgImage from "assets/images/team_top_mod.jpg";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKBox mb={8}>
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],

                  },
                })}

              >
                Echipa xDental
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} >
                Stomatologie fără compromisuri pentru sănătatea ta dentară.
              </MKTypography>
            </MKBox>
            {/* <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Ai nevoie de o programare ?
            </MKButton> */}
            <MKBox >
              <MKTypography variant="h6" color="white" mt={8} mb={1}>
                Vă așteptăm și pe social media
              </MKTypography>

              <MKBox display="flex" justifyContent="center" alignItems="center" >
                <MKTypography component="a" variant="body1" color="white" href="https://www.linkedin.com/xdentaltm/" mr={3}>
                  <i className="fab fa-linkedin" />
                </MKTypography>
                <MKTypography component="a" variant="body1" color="white" href="https://www.facebook.com/X-Dental-104933055444540/" mr={3}>
                  <i className="fab fa-facebook" />
                </MKTypography>
                <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/xdentaltm/" mr={3}>
                  <i className="fab fa-instagram" />
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          pb: 6,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.7),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />

        <MKBox component="section" sx={{ mt: { xs: 4, md: 6 } }} >
          <SectionTitle
            title=""
            description=""
            hashtag="# OUR WORK PLACE" />

          <MKBox component="section" sx={{ mt: { xs: -8, sm: 4, md: -6, lg: -4, xl: -2 } }}>
            <Gallery />
          </MKBox>

        </MKBox>
      </Card >

      <MKBox pt={2} px={1} >
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default AboutUs;
