import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";
import bgImage from "assets/images/services/treatments.jpg";

function IgienizareCorecta() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 8 }}>
            <DefaultSectionTitle title="Sfaturi pentru o igienizare corectă" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item sx={{ whiteSpace: "normal" }}>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        Pe termen mediu/lung corectitudinea și frecvența periajului sunt foarte importante.
                        Recomandăm curățarea dinților de cel puțin două ori pe zi, dimineața și seara, folosind o tehnică minim invazivă.

                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Tehnica periajului corect</b>

                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        <OptionItem text="Înclinați periuță la 45 de garade față de baza dintelui și în contact cu gingia și efectuați mișcări verticale dinspre gingie spre marginea dinților." />
                        <OptionItem text="Periați suprafața pe care muscați a fiecărui dinte, cu miscări înainte-înapoi." />
                        <OptionItem text="Periați cu grija limba pentru îndepărtarea bacteriilor și împrospatarea respiraţiei." />
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default IgienizareCorecta;