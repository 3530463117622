// Material Kit 2 React components
import MKBox from "components/MKBox";

import GoogleMapReact from 'google-map-react';
import { constants } from 'util/constants'

function Map({ location, zoomLevel }) {
    const renderMarkers = (map, maps) => {
        console.log("render");
        let marker = new maps.Marker({
            key: "marker_1",
            position: { lat: location.lat, lng: location.lng },
            map,
            title: 'Victoriei 1B'
        });
        return marker;
    };

    return (
        <MKBox
            display={{ xs: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100% - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
        >
            <GoogleMapReact
                bootstrapURLKeys={{ key: constants.MAPS_KEY }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >

                {/* <LocationPin
                    lat={location.lat}
                    lng={location.lng}
                    text={location.address}
                /> */}
            </GoogleMapReact>
        </MKBox>
    );

}

export default Map