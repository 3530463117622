// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React fragments
import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";

// Fragments
import SectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import SectionBar from "fragments/SectionTitles/SectionBar";

// Sections
import ElevatorPitch from "pages/Home/sections/ElevatorPitch";
import Arguments from "pages/Home/sections/Arguments";
import MadeSimple from "pages/Home/sections/madeSimple/MadeSimple"
import Testimonials from "pages/Home/sections/Testimonials";

// Routes
import routes from "routes";

// Images
//import bgImage from "assets/images/presentation2.png";
import bgImage from "assets/images/caramizi.png";
import ServicesSlider from "./sections/services/ServicesSlider";
import BlogSlider from "./sections/blog/BlogSlider";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

function Home() {
  const services = {
    title: "Stomatologie într-un cadru relaxat și fără durere",
    // // "Servicii de calitate pentru un zâmbet de care să fii mândru";
    description: "Beneficiază de servicii de calitate pentru un zâmbet de care să fii mândru",
    hashtag: "# Our Best Dental Services"
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Smile ! You've just found your new dentists <SentimentSatisfiedAltIcon />
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >

            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      {/* BODY */}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.7),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ElevatorPitch />
        <Arguments />

        {/* SERVICES */}
        <MKBox
          component="section"
          variant="gradient"
          bgColor="light"
          position="relative"
          py={6}
          px={{ xs: 2, lg: 0 }}
          mx={-2}
        >

          <Container sx={{ mt: 6 }}>
            <SectionTitle title={services.title} description={services.description} hashtag={services.hashtag} />
          </Container>
          <Container>
            <ServicesSlider />
          </Container>

        </MKBox>
        {/* /SERVICES */}

        {/* Made Simple */}
        <Container sx={{ mt: 4 }}>
          <SectionTitle
            title="Mai simplu, atât pentru tine, cât și pentru noi"
            description="Aceeași calitate a serviciilor, cât mai aproape de zona ta"
            hashtag="# Keep it simple" />
        </Container>
        <Container>
          <MadeSimple />
        </Container>
        {/* / Made Simple */}

        {/* Ghid pacient */}
        <Container sx={{ ml: "auto", mt: { xl: 2, md: -4, xs: -6 } }} >
          <SectionBar title="Jurnalul pacientului"
            //description="Un set cuprinzător de articole, pe diverse teme de interes, create și oferite gratuit de specialiștii xDental."
            description="Un set cuprinzător de informații utile, pe diverse teme de interes, create și oferite gratuit de specialiștii xDental."
            action={{ route: "/util", label: "Citește mai mult" }} />
          {/* <LeftSectionTitle title="Jurnalul pacientului"
            description="Un set cuprinzător de articole, pe diverse teme de interes, create și oferite gratuit de specialiștii xDental."
            action={{ route: "/pages/blog", label: "Citește mai mult" }} /> */}
        </Container>
        <Container>
          <BlogSlider />
        </Container>

        <Container sx={{ mt: { lg: 6, md: 4, xs: 4 } }}>
          <Testimonials />
        </Container>

        <Container sx={{ mt: 8 }}>
        </Container>
      </Card>
      {/* /BODY */}


      <MKBox pt={2} px={1} >
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default Home;
