import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

function OptionItem({ plusBullet, text, textVariant, textColor, lineHeight, ...props }) {
    const _variant = textVariant ? textVariant : "body1";
    const _color = textColor ? textColor : "text";
    const _lineHeight = lineHeight ? lineHeight : "1.5";
    const _bulletElem = plusBullet ? (<MKTypography variant={_variant} color={_color} lineHeight={_lineHeight}>
        &#43;
    </MKTypography>) : (<MKTypography variant={_variant} color={_color} lineHeight={_lineHeight}>
        &gt;
    </MKTypography>);
    return (
        <Grid container item xs={12} {...props}>
            <Grid item sx={{
                pl: 2,
                pr: 2
            }}>
                {_bulletElem}
            </Grid>
            <Grid item xs={10} md={11}><MKTypography variant={_variant} color={_color} lineHeight={_lineHeight}>
                {text}
            </MKTypography>
            </Grid>
        </Grid>
    );
}
//sx={{
//    mr: { xs: -4, sm: -4, md: -4, lg: -12, xl: -12 }
//}}
export default OptionItem;