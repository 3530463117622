import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, Link, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Home from "pages/Home";

// Material Kit 2 React routes
import routes from "routes";

// cookie consent
import { CookieConsentProvider, useCookieConsentContext } from '@use-cookie-consent/react'

// other Components for routing
// stoma generala
import Igienizare from "pages/Treatments/detailed/stomaGenerala/Igienizare";
import Obturatii from "pages/Treatments/detailed/stomaGenerala/Obturatii";
import AlbireaDentara from "pages/Treatments/detailed/estetica/AlbireaDentara";
import ProtezaTotala from "pages/Treatments/detailed/protetica/ProtezaTotala";
import TratamentCanal from "pages/Treatments/detailed/endo/TratamentCanal";
import CoroaneLucrariFixe from "pages/Treatments/detailed/protetica/CoroaneLucrariFixe";
import BoalaParo from "pages/Treatments/detailed/paro/BoalaParo";
import PostExtractie from "pages/UtilInfo/pages/PostExtractie";
import IgienizareCorecta from "pages/UtilInfo/pages/IgienizareCorecta";
import SemneVizitaStoma from "pages/UtilInfo/pages/SemneVizitaStoma";
import GhidProtezeTotale from "pages/UtilInfo/pages/GhidProtezeTotale";
import GrijaProtezaTotala from "pages/UtilInfo/pages/GrijaProtezaTotala";
import IgienizareCoroaneLucrari from "pages/UtilInfo/pages/IgienizareCoroaneLucrari";
import DupaTratamentCanal from "pages/UtilInfo/pages/DupaTratamentCanal";
import ImplantDentar from "pages/Treatments/detailed/implanto/ImplantDentar";
import Pedo from "pages/Treatments/detailed/pedo/Pedo";
import Orto from "pages/Treatments/detailed/orto/Orto";
import AnimaleStirbe from "pages/Blog/AnimaleStirbe";
import MultiplicaBanii from "pages/Blog/MultiplicaBanii";
import CookiePolicy from "pages/Legal/CookiePolicy";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
// @mui material components
import Snackbar from "@mui/material/Snackbar";
import MKButton from "components/MKButton";

import { useState } from 'react';

const CookieBanner = () => {
  const { acceptAllCookies } =
    useCookieConsentContext();

  const [show, setShow] = useState(true);
  const acceptAndClose = () => {
    acceptAllCookies();
    console.log("Cookies accepted");
    window.dispatchEvent(new Event("_FBConsentGrant_"));
    setShow(!show);
  }
  const toastTemplate = (
    <MKBox>
      <Grid container justifyContent="center" >
        <Grid item xs={12} alignItems="center" >
          {/* fontSize="1em"  */}
          <MKTypography color="white" fontWeight="regular" fontSize="1.1em" lineHeight="1.5" textAlign="center">
            Acest website utilizează cookie-uri necesare funcționării sale pentru atingerea scopurilor ilustrate în politica privind cookie-urile.
            Continuarea navigării reprezintă acceptul tău pentru această folosință. Dacă dorești să afli mai multe te rugăm să consulți politica de cookie-uri.
          </MKTypography>
        </Grid>
        <Stack direction="row" alignItems="center" spacing={2} mt={2} >
          <MKButton variant="gradient" color="info" onClick={acceptAndClose} >
            Sunt de acord
          </MKButton>
          <MKButton variant="gradient" color="info" component={Link} to="/politica-de-cookies">
            Politica de cookies
          </MKButton>
        </Stack>

      </Grid>
    </MKBox >
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={show}
      autoHideDuration={999999}
      //onClose={close}
      message={toastTemplate}
      sx={{
        width: "100%",
        left: "0px",
        right: "0px",
        bottom: "0px",
        opacity: 0.98,
        ml: { xs: 0 },
        mr: { xs: 0 },
        "& .MuiPaper-root": {
          borderRadius: "0.0rem"
        },
        "@media (min-width: 576px) ": {
          left: "0px",
          bottom: "0px",
          right: "auto"
        },
      }}

    />
  );
};

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/home" />} />

        {/* Tratamente */}
        <Route path="/tratamente/igienizare" element={<Igienizare />} />
        <Route path="/tratamente/obturatii" element={<Obturatii />} />

        <Route path="/tratamente/endo" element={<TratamentCanal />} />

        <Route path="/tratamente/albire" element={<AlbireaDentara />} />

        <Route path="/tratamente/proteze-totale" element={<ProtezaTotala />} />
        <Route path="/tratamente/coroane-lucrari-fixe" element={<CoroaneLucrariFixe />} />

        <Route path="/tratamente/chirurgie" element={<ImplantDentar />} />

        <Route path="/tratamente/orto" element={<Orto />} />

        <Route path="/tratamente/boala-paro" element={<BoalaParo />} />

        <Route path="/tratamente/pedo" element={<Pedo />} />

        {/* Info Utile */}
        <Route path="/util/post-extractie" element={<PostExtractie />} />
        {/* <Route path="/util/post-implant" element={<PostImplant />} /> */}
        <Route path="/util/igienizare-corecta" element={<IgienizareCorecta />} />
        <Route path="/util/vizita-stomatolog" element={<SemneVizitaStoma />} />
        {/* <Route path="/util/post-extractie-mm" element={<IgienizareCorecta />} /> */}
        <Route path="/util/acomodare-proteza-totala" element={<GhidProtezeTotale />} />
        <Route path="/util/intretinere-proteza-totala" element={<GrijaProtezaTotala />} />
        <Route path="/util/igienizare-lucrari-fixe" element={<IgienizareCoroaneLucrari />} />
        <Route path="/util/dupa-tratament-endo" element={<DupaTratamentCanal />} />

        {/* Blog */}
        <Route path="/blog/caria-la-animale" element={<AnimaleStirbe />} />
        <Route path="/blog/multiplica-banii" element={<MultiplicaBanii />} />

        {/* Legal */}
        <Route path="/politica-de-cookies" element={<CookiePolicy />} />
      </Routes>
      <CookieConsentProvider>
        <CookieBanner />
      </CookieConsentProvider>

    </ThemeProvider>

  );
}
