const qaData = {
    description: "QA data",
    sections: [
        {
            title: "Alibirea dentară",
            questions: [
                {
                    question: "Apar pete albe pe dinți după albire ?",
                    answer: "Uneori da, dar nu sunt motiv de îngrijorare. Cel mai frecvent sunt demineralizări care deja erau prezente pe dinți, albirea doar le face mai vizibile. În aprox. o săptămână vor începe să dispară."
                },
                {
                    question: "Există o limită de vârstă pentru albirea dentară ?",
                    answer: "Albirea dentară este recomandată după vârstă de 18 ani, când nervul dentar este complet dezvoltat."
                },
                {
                    question: "Cât de mult se pot albi dinții ?",
                    answer: "În funcție de tehnica de albire aleasă și de nevoile fiecărui pacient, dinții pot ajunge de exemplu de la A3,5 la A1. Nu toți pacienții reacționează la fel la albire, nuanța naturală diferă, la fel și structura dintelui, unii pacienții vor observa schimbări mai spectaculoase decât alții."
                },
                {
                    question: "Cât de mult iți afectează dinții ?",
                    answer: "O albire realizată după instrucțiuni, sub supraveghere nu iți vă afecta dinții."
                },
                {
                    question: "Albirea dentară doare ?",
                    answer: "Albirea dinților este o procedură nedureroasă. Cu toate acestea, există cazuri în care poate apărea sensibilitatea dentară. Aceasta poate dura timp de 2-3 zile după tratamentul de albire. Medicul dentist poate recomanda substanțe mineralizante sau desensibilizante care reduc perioada senzației de disconfort până la dispariția acesteia."
                },
                {
                    question: "Ce substanțe se folosesc pentru albirea dinților ?",
                    answer: "Peroxidul de hidrogen și peroxidul de carbamidă sunt cele mai eficiente și mai utilizate substanțe, efectele sunt asemănătoare, diferența ar fi că peroxidul de carbamidă acționează într-un timp mai lung. Ambele sunt la fel de eficiente dacă sunt utilizate corespunzător."
                },
                {
                    question: "Cât costă ?",
                    answer: "Pornește de la 600 lei, până la 1500 lei"
                }


            ]
        },
        {
            title: "General",
            questions: [
                {
                    question: "Cum se curăță proteza dentară ?",
                    answer: "Există periuțe speciale, dar se pot folosi și periuțe normale de dinți cu peri moi. Nu este indicată pasta de dinți sau alți detergenți puternici, recomandăm folosirea produselor specifice pentru curațare."
                },
                {
                    question: "La ce să mă aștept după un tratament de canal ?",
                    answer: "Tratamentul de canal se realizează de cele mai multe ori când există o inflamație a nervului sau o infecție la vârful rădăcinii - adică pe un teren deja inflamat și sensibil. Curățarea și dezinfectarea canalului pot produce o iritație suplimentară. De aceea, puteți resimți o durere sau disconfort la masticație după ce trece efectul anesteziei. În cazul în care durerea este constantă, recomandăm consultarea medicului stomatolog."
                }
            ]
        }
    ]
};

export default qaData;