import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";

import team from "assets/images/team/group.png";


function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
            <MKTypography variant="body1" fontWeight="light" color="text" textAlign="justify">
              Indrăznim să spunem că relația cu medicul stomatolog este una specială. <br />
              Sedințele de tratament pot fi frecvente, lungi sau de multe ori inconfortabile.<br />De ce să nu alegi o experiență mai placută ? <br />
              La <b>xDental</b>, ne-am propus să construim o relație de încredere cu pacienții noștri prin abordarea cu profesionalism a fiecarui caz într-o atmosferă destinsă.
              Știm că, într-un secol al vitezei, timpul este o resursă foarte prețioasă de aceea eficiența și calitatea actului medical sunt printre prioritățile noastre.
              <MKTypography
                component={Link}
                to={"/contact"}
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                Solicită o programare <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </MKTypography>
          </Grid>
          {/* 0 600 900 1200 1536 */}
          <Grid item xs={12} lg={6} xl={6} sx={{ ml: { xs: -2.2, sm: "auto" }, mt: { xs: 3, lg: 0 } }} >
            <MKBox
              width="100%"
              height="100%"
              borderRadius="lg"
              shadow="none"
              display={{ md: "flex" }}
              //height="calc(100vh - 2rem)"
              sx={{
                backgroundImage: `url(${team})`,
                // backgroundSize: "contain",
                //backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                transform: "scale(0.98)",
                minHeight: { xs: "16rem", sm: "26rem" },
                minWidth: { xs: "19rem", sm: "30rem" }
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
