import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FilledInfoCard from "fragments/Cards/InfoCards/FilledInfoCard";

import BasicStructure from "pages/BasicStructure";

import bgImage from "assets/images/services/treatments.jpg";
// Data
import data from "pages/UtilInfo/data/sections";


function UtilInfos() {
  const renderData = data.items.map(({ title, action }, n) => (
    <Grid item xs={12} md={7} key={n}>
      <FilledInfoCard

        variant="contained"
        icon="quiz"
        title={title}
        description=""
        action={{
          type: "internal",
          route: action.route,
          label: action.label
        }}
      />
    </Grid>

  ));

  const contentOverTopImage = (<Container>
    <Grid
      container
      item
      xs={12}
      lg={8}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ mx: "auto", textAlign: "center" }}
    >
      <MKBox mb={8}>
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],

            },
          })}

        >
          Indicații și Informații Utile
        </MKTypography>
      </MKBox>
      <MKBox >
        <MKTypography variant="h6" color="white" mt={8} mb={1}>
          Vă așteptăm și pe social media
        </MKTypography>

        <MKBox display="flex" justifyContent="center" alignItems="center" >
          <MKTypography component="a" variant="body1" color="white" href="https://www.linkedin.com/xdentaltm/" mr={3}>
            <i className="fab fa-linkedin" />
          </MKTypography>
          <MKTypography component="a" variant="body1" color="white" href="https://www.facebook.com/X-Dental-104933055444540/" mr={3}>
            <i className="fab fa-facebook" />
          </MKTypography>
          <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/xdentaltm/" mr={3}>
            <i className="fab fa-instagram" />
          </MKTypography>
        </MKBox>
      </MKBox>
    </Grid>
  </Container>);

  const bodyBgColor = "light";
  const body = (
    <Container sx={{ mt: 4 }}>
      {/* alignItems="center" justifyContent="center" */}
      <Grid container spacing={3} justifyContent="center">
        {renderData}
      </Grid>
    </Container >
  );

  return (
    <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} bodyBgColor={bodyBgColor}>
    </BasicStructure>

  );
}

export default UtilInfos;
