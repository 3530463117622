export const constants = {
    MAPS_KEY: "AIzaSyBvm5QJ_DZ6fCTCsSR5oXR0cuV-JLYtO7k",
    VICTORIEI_LOC: {
        lat: 45.752719081719995,
        lng: 21.22585375843547,
        address: "Piata Victoriei, Nr 1B"
    },

    arrowTypographyStyle: {
        width: "max-content",
        display: "flex",
        alignItems: "center",

        "& .material-icons-round": {
            fontSize: "1.125rem",
            transform: `translateX(3px)`,
            transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
        },

        "&:hover .material-icons-round, &:focus .material-icons-round": {
            transform: `translateX(6px)`,
        },
    }
}
