// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// https://tamalweb.com/compress-images-reactjs - https://tinypng.com/
function ImageBlogCard({ image, title, description, action, textAlign, imgTopOverflow, onHoverTransform }) {
  const marginTop = imgTopOverflow ? -3 : 12;
  const actionSection = action.type === 'none' ? (<Box></Box>) : (action.type === "external" ? (
    <MKButton
      component={MuiLink}
      href={action.route}
      target="_blank"
      rel="noreferrer"
      variant="gradient"
      size="small"
      color={action.color ? action.color : "dark"}
    >
      {action.label}
    </MKButton>
  ) : (
    <MKButton
      component={Link}
      to={action.route}
      variant="gradient"
      size="small"
      color={action.color ? action.color : "dark"}
    >
      {action.label}
    </MKButton>
  ));

  const onHover = onHoverTransform ? ({
    "&: hover": {
      //transform: "translate3d(0px, -4px, 5px)",
      transform: "translateY(-10px)",
      transition: "0.8s",
      backgroundColor: "rgba(10, 163, 232, 0.1)", //"rgba(26, 115, 232, 0.1)",
      //boxShadow: "0 0 1.25rem rgb(108 118 134 / 10%)",
      //borderBottom: "1px solid #06a3da"
    }
  }) : ({});

  return (
    <Card sx={onHover}>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={{ marginTop }}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign={textAlign ? textAlign : "left"}>
        <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>

        {actionSection}

      </MKBox>
    </Card >
  );
}

// Typechecking props for the ImageBlogCard
ImageBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  imgTopOverflow: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["none", "external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImageBlogCard;
