import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function AnimaleStirbe() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 6 }}>
            <DefaultSectionTitle title="Ai văzut vreodată animale știrbe ?" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>

                <Grid item textAlign="justify" >
                    <MKTypography variant="body1" color="text" mt={3} lineHeight="1.5">
                        <b>De ce nu au animalele carii ?<br />Suntem noi, oamenii, mai speciali față de restul animalelor și facem întruna carii? </b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Într-un fel da, suntem. Și nu numai în legătură cu cariile, putem să adăugăm și obezitatea și diabetul și probabil multe alte  boli ca factori ce ne diferențiază de animale.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Acesta să fie prețul inteligentei omului ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Acesta să fie prețul inteligentei omului ?<br />
                        Dar ce au totuși caria, obezitatea și diabetul în comun, pe lângă că nu le prea vedem la animalele sălbatice ?
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Alimentația. <br />
                        Dacă omul preistoric ar fi evoluat cu diverse sucuri și dulciuri lângă el poate aveau o șansă și dinții, dar cum nu s-a întâmplat asta, depinde de noi să îi salvăm.
                        <br />
                        Caria este o boală a timpului în care trăim, iar zahărul o cauzează în mod direct. Dacă nu ar fi zahăr și zaharuri, caria ar fi o boala rară - poate chiar nu ar exista.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default AnimaleStirbe;