import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function DefaultSectionTitle({ title, description, hashtag, ...props }) {

    const hashtagOptional = (hashtag) ?
        (<MKBadge
            variant="contained"
            color="info"
            badgeContent={hashtag}
            container
            sx={{ mb: 2 }}
        />) : (<></>)

    const asText = (<MKBox component="section" my={4} py={4} {...props}>
        <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: -6, mx: "auto", px: 0.75 }}
            >
                {hashtagOptional}
                <MKTypography variant="h2" fontWeight="bold">
                    {title}
                </MKTypography>
                <MKTypography variant="body1" color="text">
                    {description}
                </MKTypography>
            </Grid>
        </Container>
    </MKBox>);

    return (
        asText
    );
}

export default DefaultSectionTitle;
