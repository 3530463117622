import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";
import bgImage from "assets/images/services/treatments.jpg";

function PostExtractie() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 8 }}>
            <DefaultSectionTitle title="Indicații post-extracție" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item sx={{ whiteSpace: "normal" }}>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Imediat după extracție</b>
                        <OptionItem text="continuați să muscați pe compresa sterilă în dreptul zonei de extracție 30 min" />
                        <OptionItem text="după îndepărtarea compreselor, dacă încă se mai observă sângerare abundentă, reaplicați un alt pansament compresiv pentru încă 30 de minute" />
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Primele 24 de ore</b>
                        <OptionItem text="nu fumați" />
                        <OptionItem text="evitați exerciții fizice intense" />
                        <OptionItem text="nu consumați alcool" />
                        <OptionItem text="nu alimente prea fierbinți" />
                        <OptionItem text="nu clătiți abundent" />
                        <OptionItem text="este normală o ușoară sangerere dar în caz de sângerare abundenta contactați medicul dvs." />
                        <OptionItem text="periajul se vă relua următoarea zi dimineaţă, dar cu  grijă, menajând zona respectiva. Este indicată o periuță extra-soft(foarte moale)." />
                        <OptionItem text="în prima noapte, pentru a preveni acumularea sângelui la nivelul capului și apariția sângerării, ar fi bine să dormiți cu capul ușor ridicat (pe o perna)" />
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" >
                        <b>Primele 48 de ore</b>
                        <OptionItem text="recomandăm o diteta de consistență mai moale" />
                        <OptionItem text="nu creați presiune negativă – nu beți cu paiul, nu trageți din locul extracției" />
                        <OptionItem text="nu traumatizaţi locul intervenției, nu clătiţi insistent, nu atingeți cu degetul / limba." />
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        În cazul procedurilor mai laborioase este normal să apară umflarea zonei unde a avut loc intervenția.
                        Tumefacția maximă vă apărea la 2-3 zile de la intervenție urmând revenirea la normal pe parcursul primei săptămâni.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        Pentru limitarea tumefacției, în <b>primele 24 de ore</b> după intervenție, este indicat să țineți o compresa rece  pe obraz.
                        Acest lucru va reduce  semnificativ șansele de inflamare (umflare) a obrazului. În acest sens, cel mai  bine este să
                        puneți o pungă cu gheață. Se va aplica alternativ în reprize de 20 de minute urmate de o pauza de 20 de minute și tot așa.
                        De asemenea, statul în șezut și nu culcat poate limita tumefacția.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        În cazul în care s-au folosit <b>fire de sutură</b>, acestea se îndepărtează în 7-14 zile după intervenție.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default PostExtractie;