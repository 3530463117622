const actionLabel = "Mai mult";
const infos = {
    description: "Indicatii si informatii",
    items: [
        {
            title: "Indicații post-extracție",
            action: { route: "/util/post-extractie", label: actionLabel }

        },

        // {
        //     title: "Indicații post inserare implant",
        //     action: { route: "/util/post-implant", label: actionLabel }

        // },

        {
            title: "Sfaturi pentru o igienizare corectă",
            action: { route: "/util/igienizare-corecta", label: actionLabel }

        },

        {
            title: "Semne că ar trebui să merg la stomatolog",
            action: { route: "/util/vizita-stomatolog", label: actionLabel }

        },

        // {
        //     title: "Indicații post-extracție molar de minte",
        //     action: { route: "/util/post-extractie-mm", label: actionLabel }

        // },

        {
            title: "Ghid acomodare proteze totale",
            action: { route: "/util/acomodare-proteza-totala", label: actionLabel }

        },
        {
            title: "Cum am grijă de proteza totală",
            action: { route: "/util/intretinere-proteza-totala", label: actionLabel }

        },
        {
            title: "Igienizarea coroanelor și lucrărilor fixe",
            action: { route: "/util/igienizare-lucrari-fixe", label: actionLabel }

        },
        {
            title: "La ce să mă aștept după un tratament de canal",
            action: { route: "/util/dupa-tratament-endo", label: actionLabel }

        },
    ]
};

export default infos;