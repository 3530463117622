// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 React fragments
import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";
// Routes
import routes from "routes";
// Data
import CollapsableRow from "fragments/CollapsableRow";
import data from "pages/FAQ/data/qa";

function FAQ() {

  const renderData = data.sections.map(({ title, questions }, i) => (
    <MKBox p={3} key={i}>
      <MKTypography variant="subtitle1" color="dark" fontWeight="bold" mb={2}>
        {title}
      </MKTypography>
      {questions.map(({ question, answer }, n) => (
        <CollapsableRow title={question} description={answer} mt={2} key={n} titleVariant="h6" descriptionVariant="body1" descriptionFontSize="1rem" />
      ))}
    </MKBox>

  ));

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox component="section" >
        <Container>
          <Grid container alignItems="center">

            <Grid
              item
              xs={12}
            >
              <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 20, sm: 18, md: 20 }}
                mb={{ xs: 20, sm: 18, md: 20 }}
              >
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  coloredShadow="info"
                  borderRadius="lg"
                  p={2}
                  mx={2}
                  mt={-3}
                >
                  <MKTypography variant="h3" color="white">
                    Poate te-ai întrebat ...
                  </MKTypography>
                </MKBox>
                <MKBox px={4} py={4}>
                  {renderData}
                </MKBox>

              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <MKBox mt={-10} >
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default FAQ;
