/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import ImageCard from "fragments/Cards/BlogCards/ImageBlogCard";

// Data
import data from "pages/Home/sections/data/servicesData";

function ServicesSlider() {
    const noAction = { type: 'none', route: 'none', label: 'none' };
    const renderData = data.items.map(({ image, title, description, icon, route, key }) => (
        <SwiperSlide key={key}>
            {/* <Link to={route}> */}
            <ImageCard image={image} name={key} title={title} description={description} action={noAction} onHoverTransform='true' />
            {/* </Link> */}
        </SwiperSlide>

    ));

    return (
        <Container sx={{ mt: 6 }}>
            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    480: {
                        slidesPerView: 2,
                    },
                    780: {
                        slidesPerView: 3,
                    },
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={false}
                slidesPerView={"1"}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {renderData}
            </Swiper>
        </Container>
    );
}

export default ServicesSlider;
