import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function ProtezaTotala() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Proteza totală" description="" sx={{ mt: { md: 2, xs: 0 } }} />

            {/* <MKBox sx={{ my: { xs: 2, sm: 6 }, mx: 12 }} >
                <Divider />
            </MKBox> */}
            <Grid container item xs={12}>

                <Grid item textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Proteza totală reprezintă ultima soluție terapeutică în evoluția patologiei dentare. Apelăm la acest tratament în momentul în care pe o arcadă dentară rămân mai puțin de 3-4 dinți.
                        În cele mai multe cazuri, pacienții sunt reticenți, se tem că nu se pot obișnui cu proteza și doresc să știe detalii despre ce proces, cum va arăta și întrețirea acesteia.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Tratamentul</b> are loc în mai multe ședințe în care se testează pe rând noii parametrii după care vă fi realizată proteza.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Estetica</b> este un factor la care protezarea finală nu dezamăgește, pacientul putând să-și facă dinții exact cum vrea, poate alege forma, culoarea, iar poziția dinților este una ideală.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Primul lucru pe care îl cerem de la pacient este dorința de a avea o proteza și mai aves motivația de a se acomoda cu ea. De obicei perioada de acomodare este de cel puțin 2 săptămâni.
                        <br />În momentul începerii tratamentului este important că pacientul să știe la ce se aștepte. Senzația protezei în gura nu se aseamănă deloc cu senzația când erau dinți prezenți. Pacientul vă putea vorbi foarte bine cu protezele, vă putea să mestece dar nu vă mai putea să muște dintr-un mar, alimentele trebuind tăiate înainte.
                        <br />Este normal că proteza să jeneze iniţial şi să apese anumite zone, unde pot inflamații și dureri. De aceea pacientului i se recomandă o nouă vizită următoarea zi pentru rezolvarea acestor mici problem. Se întâmplă ca pacientul să revină de câteva ori până când proteza vă fi complet adaptată.

                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Se recomandă</b> ca protezele să fie curăţate de două ori pe zi, de preferinţă seara înainte de culcare, cu apă şi săpun lichid şi o perie separată, special folosită pentru proteză. Nu este indicată pasta de dinţi în curătarea protezei, fiindcă în timp duce la pierderea luciului şi retenţionarea mai uşoară a plăcii bacteriene şi  pe suprafaţa acesteia.
                        În plus, de două ori pe săptămână recomandăm a se lăsa peste noapte proteza într-un recipient cu o substanţă efervescentă, dezinfectantă, special creată pentru proteza dentară.

                    </MKTypography>

                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default ProtezaTotala;