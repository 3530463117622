import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";
import igienizare from "assets/images/services/igienizare.jpg";

function Igienizare() {

    const contentOverTopImage = "";
    const placaDentaraText = "Placa bacteriană este o peliculă incoloră, aderentă, formată din bacterii şi zaharuri, care se acumulează în permanenţă pe dinţi." +
        " Aceasta este principala cauză a cariilor dentare şi afecţiunilor gingivale, iar dacă nu este îndepărtată zilnic duce la formarea tartrului.";
    const tartruText = "Tartrul este o acumulare de placă bacteriană care s-a mineralizat (întărit) pe dinţi. "
        + "Tartrul se poate forma la nivelul şi sub joncţiunea mucogingivală şi poate irita ţesutul gingival. "
        + "El favorizează acumularea continuă a plăcii bacteriene, având o suprafaţă foarte aderentă, ceea ce în timp duce la afecţiuni grave precum formarea cariilor dentare şi afecţiuni gingivale.";

    const body = (
        <Container sx={{ mt: 6, mb: 2 }} >
            <DefaultSectionTitle title="Igienizarea profesională" description="" sx={{ mt: { md: 2, xs: 0 } }} />

            {/* <MKBox sx={{ my: { xs: 2, sm: 6 }, mx: 12 }} >
                <Divider />
            </MKBox> */}
            <Grid container item spacing={4} xs={12} textAlign="justify">
                <Grid item xs={12} sx={{ mt: { md: 0, xs: 2 } }}>
                    <MKTypography variant="h4" color="dark" mb={2} mt={2}>
                        Noțiuni
                    </MKTypography>
                </Grid>

                <Grid item xs={12} lg={6} xl={6}>
                    <MKTypography variant="h5" color="text" mt={0}>
                        Placa bacteriană
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={1} mt={2}>
                        {placaDentaraText}
                    </MKTypography>

                    <MKTypography variant="h5" color="text" mt={2}>
                        Tartru
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={1} mt={2}>
                        {tartruText}
                    </MKTypography>
                </Grid>
                <Grid item xs={12} sm={10} md={10} lg={6} xl={6} minHeight="50vh">
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${igienizare})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} lg={12} textAlign="justify">
                <Grid item lg={12} sx={{ mt: { md: 2, xs: 2 } }}>
                    <MKTypography variant="h4" color="dark" mb={2} mt={2}>
                        Igienizarea profesională
                    </MKTypography>
                </Grid>
                <Grid item >
                    <MKTypography variant="h5" color="text" mt={2}>
                        Prima etapă - Detartrajul
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={1} mt={2}>
                        Detartrajul este o curățare mai profundă ce constă în îndepărtarea depozitului subgingival de tartru
                        (substanță calcaroasă de culoare gălbuie sau negricioasă, care se depune pe coroana dinților), prin intermediul aparatului cu ultrasunete.
                    </MKTypography>
                </Grid>

                <Grid item >
                    <MKTypography variant="h5" color="text" mt={2}>
                        A 2a etapă - Airflow
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={1} mt={2}>
                        Airflow-ul dentar reprezintă o metodă de igienizare dentară prin curatarea dinților cu un dispozitiv care generează un jet cu presiune mare cu apă, aer și particule.
                        Dispozitivul de AirFlow pulverizează pe suprafața dinților și a gingiilor amestecul de pulbere ușor abrazivă (bicarbonat de sodiu), aer și apă.
                        Pudra abrazivă sablează atât fețele vizibile ale dinților, cât și zonele interdentare, altfel mai greu accesibile.
                    </MKTypography>
                </Grid>

                <Grid item >
                    <MKTypography variant="h5" color="text" mt={2}>
                        Ultima etapă - Periajul
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={1} mt={2}>
                        Periajul este asemănator cu cel realizat acasă, numai că este mai riguros și ajunge pe toate suprafețele dentare.
                    </MKTypography>
                </Grid>

            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default Igienizare;