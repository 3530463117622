import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";
import OptionItem from "fragments/OptionItem";

function GrijaProtezaTotala() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 0 }}>
            <DefaultSectionTitle title="Cum am grijă de proteza totală" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item >
                <Grid item xs={12} sx={{ whiteSpace: "normal" }} textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <br />O igienizare riguroasă trebuie realizată zilnic, dar trebuie ținut cont că materialul
                        din care este confecționată proteza se poate zgâria ( își pierde luciul și retentioneaza alimente)
                        sau dacă este scapătă se poate chiar fractura.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Indicații de curățare:</b>
                    </MKTypography>
                    <OptionItem mt={1} text="Curățați protezele deasupra unui vas cu apă sau deasupra chiuvetei pline cu apă, în caz că o scăpați să nu exite riscul să se fractureze." />
                    <OptionItem text="Există periuțe speciale, dar se pot folosi și periuțe normale de dinți cu condiția să nu fie prea tari" />
                    <OptionItem text="Folosiți lichid de spălat vase sau un produs specific pentru curățarea protezelor. Nu este indicată pasta de dinți sau alti detergenți puternici" />
                    <OptionItem text="Periați cu grijă toate suprafețele protezei" />
                    <OptionItem text="Nu uitați că atunci când vă îndepărtați proteza, să vă curățați și dinții rămași, gingiile precum și limba" />
                    <OptionItem text="După mese s-ar putea să fie nevoie să clătiți proteza sub un jet puternic de apă." />
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Păstrarea pe timpul nopții:</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        De fiecare dată când proteza nu se află în cavitatea bucală este indicat să se țină într-un pahar cu apă, mai ales pe timpul nopții.
                        Se pot adăuga și pastile efervescente pentru curățare. Dacă nu deranjază, recomandăm purtarea protezei și pe timpul nopții după efectuarea igienizării.
                    </MKTypography>
                </Grid>

            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default GrijaProtezaTotala;