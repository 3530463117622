import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";

import bgImage from "assets/images/services/treatments.jpg";

function CoroaneLucrariFixe() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Coroane și lucrări dentare fixe" description="" sx={{ mt: { md: 2, xs: 0 } }} />

            <Grid container item xs={12}>
                <Grid item textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Caracteristici:</b>

                        <OptionItem text="îmbracă în totalitate dintele/ dinții" />
                        <OptionItem text="dacă se îmbracă doar un dinte -&gt; coroana dentară" />
                        <OptionItem text="dacă se îmbracă mai mulți dinți între care există dinți lipsă -&gt; lucrare dentară (punte)" />
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>De ce alegem să îmbracăm un dinte?</b>
                        <OptionItem text="să îi creștem durata de viață" />
                        <OptionItem text="să-l facem să arate mai bine în caz că nu suntem mulțumiți de aspect" />
                        <OptionItem text="să înlocuim dinții lipsa" />
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Daca intrăm în detalii, decizia terapeutică de a îmbracă un dinte se face pe următoarele criterii:
                        <OptionItem text="dinte care a suferit distrucții masive cu perderi importante de smalț și dentină" />
                        <OptionItem text="dinți devitali - Dintele este un țesut viu în interiorul căruia exita canalicule care 
                        fac legătură între camera pulpară și suprafață externa a dentelui, este un țesut care are sensibilitate 
                        și raspunde la factorii de mediu. În momentul în care nervul își pierde vitalitatea structura dentară 
                        își pierde rezistența, iar în timp ajunge să se fractureze."/>
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Lucrarea protetica fixă din mai multe elemente combină defapt mai multe coroane, se adaugă în plus rolul de a înlocui și dinți lipsă. Întrebarea care se pune întotdeauna – <b>ce tip de coroane să ne alegem?</b>
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Primim la stomatolog o listă de preturi ne gândim că ce e mai scump e mai bun, dar totuși nu suntem siguri dacă diferență de preț este justificată.
                        <br />Soluțiile de protezare definitivă pe care le oferim:
                        <OptionItem text="coroană metalo-ceramică" />
                        <OptionItem text="coroană de zirconia" />
                        <OptionItem text="coroană din ceramică EMAX" />
                        <br />cu o îngrijire corespunzătoare pot fi toate variante foarte bune de tratament, trecând testul timpului.
                        <br />Toate îndeplinesc standardul popular „din porțelan”, diferență majoră constă în faptul că ultimele 2 variante nu conțin metal, facandu-le mai biocompatibile.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Important de reținut este că obiectivul final al coroanei este să crească durata de viață a dintelui. Cu alte cuvinte scopul nu este rezistența pe termen nedeterminat a coroanei ci a dintelui, de aceea adaptăm coroana să servească dintele nu sacrificăm dintele pentru coroană.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Există și variate mai „ieftine” - coroana metalo-acrilică sau coroana metalo-compozit - însă proprietățile lor sunt nesatisfăcătoare. În timp, suprafața acestora, care este defapt un plastic, se impregnează cu coloranți și bacterii, afectând prognosticul pe termen lung.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default CoroaneLucrariFixe;