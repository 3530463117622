import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function Obturatii() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Obturația (plomba) dentară" description="" sx={{ mt: { md: 2, xs: 0 } }} />

            {/* <MKBox sx={{ my: { xs: 2, sm: 6 }, mx: 12 }} >
                <Divider />
            </MKBox> */}
            <Grid container item xs={12} textAlign="justify">

                <Grid item >
                    <MKTypography variant="body1" color="text" mt={2}>
                        Tratarea dintelui cu o plombă se realizează în urma distrucției țesutului dentar dur de către afecțiunea carioasă.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Caria dentară este cea mai frecventă afecțiune umană. în momentul de față, poate 99 la sută dintre noi o să facem cel puțin
                        o carie pe parcursul vieții - <b>dar este oare obligatoriu ? Chiar nu avem ce face în privința asta?</b> Bineînteles că avem, totul
                        depinde de <b>ingiena orală și de obiceiurile alimentare</b>.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Igiena orală corectă am descris-o în alt articol,{" "}
                        <MKTypography component={Link} to="/util/igienizare-corecta" variant="body1" color="text" fontWeight="regular">
                            <u>vezi link</u>
                        </MKTypography>.
                        Iar ca obiceiuri alimentare, carbohidrații sunt cei incriminați
                        – zahărul în mod special fiind unul dintre cele mai importante componente în dezvoltarea cariei. Suprasimplificând, bacteriile aderă de
                        resturile alimentare rămase pe dinți, se alimentează cu zahăr și produc acizi care atacă dintele. Cât timp dintele este expus
                        acestui atac caria continuă să progreseze.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Ca orice lucru, și caria are un început. <b>Demineralizarea</b> – nu este considerată încă carie - apare ca o pată albă, faza incipientă
                        a atacului acid, smalțul nefiind înca distrus, doar demineralizat. În această fază, dacă oprim acțiunea factorilor cauzali, dintele
                        se poate remineraliza, întorcandu-se la starea de sănătate. <b>Ce trebuie sa retinem</b> – o carie depistată încă de la început la un
                        control de rutină poate fi tratată foarte ușor. Cu cât avansează distrucția smalțului și a dintelui, cu atât tratamentul va fi mai
                        complex, în ultima fază se poate ajunge până la extracția dintelui.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Ca și <b>simptome principale</b> pentru caria simplă – sensibilitate la rece, la dulce, curent, ușoară durere care ar trebui să treacă
                        repede de la îndepartatrea agentului care a cauzat durerea (10-15 sec).
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2}>
                        Plomba înlocuiește țestutul distrus prin carie readucând dintele la forma inițială.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" lineHeight="1.5">
                        Durata medie de viață a unei plombe este de 5 ani, după acest timp sunt recomandate consultații regulate, medicul stomatolog va
                        lua decizia dacă plomba trebuie înlocuită sau nu.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default Obturatii;