// @mui material components
import Snackbar from "@mui/material/Snackbar";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import toastStyles from "./toastStyles";

function ToastMessage({ message, toastStyleName, show, toggleFunction, autoHideMs }) {
    const toastStyle = toastStyleName ? toastStyles[toastStyleName] : toastStyles.default;
    const toastTemplate = (
        <MKBox display="flex" justifyContent="space-between" alignItems="center" color="white">
            {message}
            <CloseIcon
                fontSize="medium"
                sx={{ ml: 4, mr: -1, cursor: "pointer" }}
                onClick={toggleFunction}
            />
        </MKBox>
    );

    const _autoHideMs = autoHideMs ? autoHideMs : 3000;

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={show}
            autoHideDuration={_autoHideMs}
            onClose={toggleFunction}
            message={toastTemplate}
            sx={toastStyle}
        />
    );
}

export default ToastMessage;