import { useState } from "react";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export default function CollapsableRow({ title, titleVariant, titleColor, price, description, descriptionVariant, descriptionFontSize }) {
    const [expanded, setExpanded] = useState(false);

    const _titleVariant = titleVariant ? titleVariant : "h6";
    const _titleColor = titleColor ? titleColor : "text";
    const _descriptionVariant = descriptionVariant ? descriptionVariant : "body2";
    const _descriptionSize = descriptionFontSize ? descriptionFontSize : "1rem";

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    //sx={{ borderLeft: "0.0625rem solid rgb(222, 226, 230);" }}
    const priceBox = price ? (
        <MKBox px={2} py={1}>
            <MKTypography variant={_titleVariant} fontWeight="bold" color="text">
                {price}
            </MKTypography>
        </MKBox>) : ("");

    return (
        <MKBox sx={{ cursor: "pointer" }}>
            {/* intrebarea */}
            <MKBox onClick={handleExpandClick} mt={2} pb={1} pt={1} display="flex" sx={{ justifyContent: "space-between", alignItems: "center", borderBottom: "0.0625rem solid rgb(222, 226, 230);" }}>
                <MKTypography variant={_titleVariant} fontWeight="bold" color={_titleColor}>
                    {title}
                </MKTypography>

                <MKBox display="flex" flexDirection="row">
                    {priceBox}
                    <ExpandMore
                        expand={expanded}
                        aria-expanded={expanded}
                        aria-label="show more"
                        color={_titleColor}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </MKBox>
            </MKBox>

            {/* Raspunsul */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <MKBox width="100%" pb={2} pt={2} >
                    <MKTypography color="text" variant={_descriptionVariant}
                        fontSize={_descriptionSize} lineHeight="1.5" sx={{ whiteSpace: "pre-line" }} >
                        {description}
                    </MKTypography>
                </MKBox>
            </Collapse >

        </MKBox >
    );

}