import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";
import CollapsableRow from "fragments/CollapsableRow";
import bgImage from "assets/images/services/treatments.jpg";

import { useEffect } from 'react';

function AlbireaDentara() {

    useEffect(() => {
        window.dispatchEvent(new Event("_FBAlbireDentara_"));
    });

    const contentOverTopImage = "";
    const peScurt =
        (<MKTypography textAlign="justify">Pentru că am scris mult pe acest subiect o să îi ajutăm și pe cei care nu au timpul necesar să treacă prin tot materialul cu un rezumat.<br />
            <br /><b>Înainte de orice tratament</b> trebuie să ne asigurăm că nu avem alte probleme dentare iar dinții trebuie să fie igienizați.<br />
            Există metode de albire realizate de medic, direct pe scaunul stomatologic sau prescrise la domiciliu. Și există metode de albire acasă cu produse comandate online
            sau cumpărate direct de tine din magazine – benzi, geluri, diverse gutiere, paște de dinți, ape de gură etc.<br />
            Toate pot să funcționeze, diferența o face timpul în care acționează și rezultatul obținut(cât de mult se vor albi dinții).
            Chiar și un periaj dentar corect făcut acasă poate să îmbunătățească aspectul dinților.<br />
            Noi chiar recomandăm ca înainte să apelezi la stomatolog să încerci un mijloc din categoria a doua pentru albire – indicăm de obicei paste de dinți de la branduri cunoscute pentru că acestea au fost studiate, știi că acestea nu prezintă riscuri—poate ajungi la culoarea dorită.
            <br /><br />Deși sunt foarte multe alte modalități trebuie să fim foarte atenți ce cumpărăm, cum folosim și să cunoaștem primele semne că s-ar putea să avem probleme.
            Dacă nu ai ajuns încă la culoarea dorită de ex dacă ați utilizat o pastă de dinți pentru albire pe perioada recomandată atunci vă puteți orienta către o albire în cabinet.
            <br /><b>Care este avantajul albirii în cabinet ?</b> - control absolut a zonei în care se depune substanța (de obicei pe fața dinților care se vede) asfel se pot utiliza substanțe mult mai concentrate iar efectul va fi spectaculos.
            <br />Poate să apară sensibilitate, care va descrește ca intensitate în următoarele zile după albire.<br />
            Albirea nu este permanentă, mai repede sau mai târziu dinții se vor reînchide la culoare – dar există metode de întârziere a acestui lucru și metode de păstrare a culorii.
            <br /><br />Sperăm că v-ați făcut o idee despre ce presupune albirea dentară, pentru cei care vreți să înțelegeți mai în detaliu v-am pregătit o lectură destul de lungă în continuare.
        </MKTypography>);
    const cePresupune = (<MKTypography textAlign="justify">
        Culoarea dinților diferă în mod natural de la persoană la persoană, în plus apare și o impregnare a suprafeței dinților de-a lungul timpului din cauza alimentelor ce conțin diverși coloranți.
        <br /><br />Tratamentul presupune anihilarea acestor coloranți folosind diverși agenți cu rol de agent de albire.
        <br /><b>Peroxidul de hidrogen</b> și <b>peroxidul de carbamidă</b> sunt cele mai eficiente și mai utilizate substanțe, efectele sunt asemănătoare, diferența ar fi că peroxidul de carbamidă acționează într-un timp mai lung. Ambele sunt la fel de eficiente dacă sunt utilizate corespunzător.
        <br />Acțiunea de albire a peroxidului de hidrogen se datorează oxidării și eliberării oxigenului. Acest oxigen va reacționa cu materia colorantă pentru o face incoloră.
        <br />Aceste 2 substanțe se folosesc și în cabinet dar se găsesc și în multe produse utilizate acasă pe cont propriu. Diferența o face concentrația în care sunt folosite.
        În cabinet concentrațiile sunt mari - până la 45 la sută, pe când cele folosite acasă au concentrații mult mai mici.
        Bineînțeles că o concentrație mare înseamnă un efect aproape instant – o singură ședință la stomatolog - pe când la cele folosite acasă efectul poate să apară
        în câteva săptămâni de utilizare zilnică.
        <br />Chiar și în concentrații mici, pot să lezeze dinții și gingiile la utilizări excesive sau repetate,
        concentrația maxim admisă la cele folosite acasă fiind de 6 la sută. Numai medicul stomatolog poate asigura un control absolut la aplicare.
        <br /><br />Pe lângă cele 2 menționate mai există numeroase substanțe care se pot achiziționa pentru utilizarea acasă:
        acizi slabi, enzime, cărbune, unele dintre ele pot avea un efect benefic dar rezultatul final va fi net inferior metodelor din cabinet.
    </MKTypography>);

    const tipuriDeAlbire = (<MKTypography textAlign="justify">
        O să împărțim în 2 tipuri principale
        <br /><b>Primul tip</b>: albirea în cabinet - efectuată de către medicul stomatolog sau supravegheată de medicul stomatolog.
        <br /><b>Al doilea</b> tip este efectuată la domiciliu și fără supervizarea stomatologului, cuprinde mult mai multe metode –
        folosind substanțe naturiste până la peroxid de hidrogen:
        <OptionItem textColor="body2" textColor="body2" text="Paste de dinți" />
        <OptionItem textColor="body2" text="Geluri cu aplicare în gutiere" />
        <OptionItem textColor="body2" text="Benzi" />
        <OptionItem textColor="body2" text="Creioane" />
        <OptionItem textColor="body2" text="Gutiere cu activare luminoasă" />
        <br />Și lista continuă, comercianții nu duc lipsă de creativitate ...
    </MKTypography>);

    const stiuInainte = (<MKTypography textAlign="justify">
        Există contraindicații:
        <OptionItem textColor="body2" text="Nu toate colorațiile se pot trata la fel" />
        <OptionItem textColor="body2" text="Se asigură că substanța activă nu va intra în contact cu eventuale zone de sensibilitate sau carii" />
        <OptionItem textColor="body2" text="Sensibilitatea dentară, dacă există, se tratează înainte" />
        <OptionItem textColor="body2" text="Plombele vechi se schimbă după albire, la fel și eventuale coroane se fac după albire." />
    </MKTypography>);

    const procedura = (<MKTypography textAlign="justify">
        <b>Prima</b> dată o să vorbim despre albirea realizată în cabinet de către medicul dentist.
        <br />Tratamentul în cabinet presupune izolarea dinților de gingie și de restul cavității bucale
        {/* <br /><br />POZA<br /> */}
        <br />Substanța este sub formă de gel și se va aplica strict pe suprafața dentară vizibilă
        {/* <br /><br />POZA<br /> */}
        <br />Peroxidul de hidrogen, având o concentrație foarte mare, contactul cu gingia sau cu buzele ar determina arsuri, de aceea nu poate fi folosit decât în cabinet
        <br />Se așteaptă 15 - 20 de minute, gelul se spală, apoi se încheie prima aplicare. Va fi nevoie de 2 - 4 aplicări pentru un rezultat cât mai bun
        <br />Tratamentul de albire în cabinet se încheie după aprox. o oră
        <br />Există și aici mai multe variante:
        <OptionItem textColor="body2" text="aplicare simplă" />
        <OptionItem textColor="body2" text="activare cu lampa zoom – sensibilitate diminuată, gel mai puțin concentrat, efect de albire mai puternic, preț dublu" />
        <OptionItem textColor="body2" text="activare cu laser" />
        <br /><br /><b>Cea de-a doua</b> metodă, este efectuată acasă de către pacient la recomandarea medicului și după consultul în cabinet.
        Se folosesc două gutiere dentare (superioară și inferioară) în care se lasă o soluție de albire mai puțin concentrată și se poartă timp de 4 - 6 ore pe zi (
        sau chiar peste noapte), cel puțin 5 zile (detaliile exacte vor fi recomandate de către medicul care v-a făcut albirea). Albirea poate fi efectuată
        chiar și pe o perioadă mai îndelungată și se pot achiziționa seringi pentru aplicări suplimentare dacă doriți să aduceți culoarea dinților cât mai aproape de alb
        sau pentru menținerea culorii
        <br /><br />Diferenţa între cele 2 metode prezentate mai sus este timpul mult mai scurt al celei din cabinet, însă cu o concentrație a substanţei active mai mare.

    </MKTypography>);

    const albireaAcasa = (<MKTypography textAlign="justify">
        <b>Ai ales albirea acasă ? iată ce trebuie avut în vedere</b>
        <br />Albirea dentară profesională efectuată acasă este metoda aleasă de persoanele mai răbdătoare. Simptomele sunt mai mici decât în cazul albirii efectuate în cabinet.
        <br /><br /><b>Indicații</b> valabile în cazul chitului de <b>albire</b> la domiciliu <b>Philips ZOOM</b>:
        <OptionItem textColor="body2" text="Pentru albirea dentară acasă, vom pune la dispoziție un set de gutiere speciale pentru dinţii dvs. şi un kit de albire." />
        <OptionItem textColor="body2" text="Înainte de a folosi kitul de albire, recomandăm citirea cu atenţie a instrucţiunilor care îl însoţesc" />
        <OptionItem textColor="body2" text="Lăsaţi un strop de gel în dreptul fiecărui dinte din gutieră şi apoi aplicaţi gutiera în gură.
        Gutierele trebuie purtate timp de 4-6 ore pe zi, între 5-7 zile pentru a obţine rezultate maxime. 
        Este indicat să vă periaţi dinţii înainte de a introduce gutierele, îndepărtând astfel placa bacteriană pentru o mai bună eficacitate a gelului."/>
        <OptionItem textColor="body2" text="După folosirea gutierelor este foarte important să le curăţaţi bine pentru următoarea folosire. 
        Spalaţi gelul rămas cu apă calduţă şi folosiţi o periuţă de dinţi pentru a îndepărta gelul restant întrucât acesta 
        se va întări până la următoarea folosire şi va fi mai greu de îndepărtat, procedura de albire fiind mai puţin eficientă."/>
        <OptionItem textColor="body2" text="După fiecare ciclu de albire, evitaţi să fumaţi sau să consumaţi timp de 1 oră orice aliment sau lichid închis la 
        culoare precum: mure, vişine, cafea, cola, ceaiuri etc."/>
        <OptionItem textColor="body2" text="Pentru a vă păstra culoarea obţinută cât mai mult timp sunt valabile indicațiile de la rubrica 'Ce trebuie să am în vedere după'" />
    </MKTypography>);

    const dupaAlibire = (<MKTypography textAlign="justify">
        <b>Sensibilitatea, simptome, tratare simptome, cum mențin, tratament de menținere</b>
        <br />
        <br />După albirea profesională a dinţilor efectuată în cabinet este normal ca dinţii să prezinte o sensibilitate mai accentuată.
        <br />
        <br />Există posibilitatea ca modificările de temperatură să le percepeţi mai intens decât înainte. Aceste senzaţii sunt percepute timp de 2-3 zile. Este indicat ca în acest timp să luaţi câte un antiinflamator la nevoie (max 2-3 pe zi) şi să urmaţi anumite indicaţii pentru ca efectul de albire să dureze mai mult:
        <br />
        <br />Recomandăm să evitaţi consumul alimente sau băuturi foarte închise la culoare precum ceaiul, cafeaua, murele, vinul roșu, cireşele etc. timp de 5 - 7 zile precum și fumatul pentru 24 de ore.
        <br />
        <br />După prima săptămână, alimentele închise la culoare vor continua să coloreze dinții dar într-un ritm mult diminuat. Bineînțeles, nu putem renunța la aportul nutriv al acestora, dar putem limita timpul în care coloranții sunt în contact cu dinții prin:  igienizări mai frecvente, consumul de alimente cu rol de autocurățire (ex mere) sau în cazul lichidelor prin folosirea paiului.
        <br />
        <br />De asemenea, puteți folosi paste de dinţi pentru dinţi sensibili dacă dinţii prezintă sensibilitate crescută la diferenţe de temperatură.
    </MKTypography>)

    const sensibilitate = (
        <MKTypography textAlign="justify">
            Trebuie evaluată sensibiliatea dentară atât înainte cât și după tratament.
            <br />Știind gradul de <b>sensibilitate înainte de tratament</b> putem stabili parcursul ședinței de albire.
            <br />În caz de hipersensibilitate, în locul unei ședințe de o oră tratamentul se poate împărți în mai multe ședințe de 20 sau 30 de minute, sau se pot aplica metode de reducere a sensibilității, astfel știm că pacientul nu va pleca cu o sensibilitate deranjantă.
            <br /><b>Sensibilitatea post tratament</b> de obicei dispare în 2-3 zile și este, în majoritatea cazurilor, ușor tolerată.
            <br /><b>În cazul sensibilității persistente</b> se va folosi în prima fază o pastă de dinți desensibilizantă, până la procedee de desensibilizare în cabinet:
            <OptionItem textColor="body2" text="GC mousse" />
            <OptionItem textColor="body2" text="Apă de gura cu fluor" />
            <OptionItem textColor="body2" text="Alte geluri" />
        </MKTypography>
    )

    const intrebariSfaturi = (
        <MKTypography textAlign="justify">
            <b>Pete albe pe dinți după albire ?</b>
            <br />Nu sunt motiv de îngrijorare, cel mai frecvent sunt demineralizări care deja erau prezente pe dinți, albirea doar le face mai vizibile. În aprox o săptămână vor începe să dispară.
            <br /><br />
            <b>Există o limită de vârstă pentru albirea dentară ? </b>
            <br />Albirea dentară este recomandată după vârstă de 18 ani, când nervul dentar este complet dezvoltat.
            <br /><br />
            <b>Cât de mult se pot albi dinții ? </b>
            <br />În funcție de tehnica de albire aleasă și de nevoile fiecărui pacient, dinții pot ajunge de exemplu de la A3,5 la A1.
            <br />Nu toți pacienții reacționează la fel la albire, nuanța naturală diferă, la fel și structura dintelui, unii pacienți vor observa schimbări mai spectaculoase decât alții.
            <br /><br />
            <b>Albirea dentară doare ? </b>
            <br />Albirea dinților este o procedură nedureroasă. Cu toate acestea, există cazuri în care poate apărea sensibilitatea dentară. Aceasta poate dura timp de 2-3 zile după tratamentul de albire. Medicul dentist poate recomanda substanțe mineralizante sau desensibilizante care reduc perioada senzației de disconfort până la dispariția acesteia.
            <br /><br />
            <b>Cât persistă efectul albirii dentare profesionale ? Cum să ai dinții mai albi ? </b>
            <br />Efectul obținut în urma tratamentului de albire dentară poate dura de la 6 luni până la un an și chiar mai mult, în funcție de igiena dentară și modul în care sunt urmate indicațiile medicului stomatolog. Vezi rubrica – <b>"Ce trebuie să am în vedere după ?"</b>
            <br /><br />Există mai multe tipuri de albire în cabinetul stomatologic
            <br />Majoritatea se realizează cu geluri cu peroxid, diferența este dacă gelul este activat sau nu de o sursa luminoasa.
            <br />În cazul gelului activat de un laser sau o lampă efectul de albire poate să fie mai pronunțat și sensibilitatea postalbire mai diminuată. Dar costul este în general de 2 ori mai mare.
            <br /><br />
            <b>Cât costă ?</b>
            <br />Pornește de la 400 lei, până la 1200 lei
            <br /><br />
            <b>Cum pot albi dinții definitiv ? </b>
            <br />Doar cu coroane sau fațete dentare
            <br /><br />
            <b>Semne că ar trebui să mă opresc, să las dinții să se remineralizere</b>
            <OptionItem textColor="body2" text="Durere în momentul albirii" />
            <OptionItem textColor="body2" text="Sensibilitate persistentă postalbire" />
            <OptionItem textColor="body2" text="Pete albe pe dinți" />
            <br /><br />
            <b>Cât de mult iți afectează dinții ?</b>
            <br />O albire realizată după instrucțiuni, sub supraveghere nu îți vă afecta dinții.
        </MKTypography>
    );

    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Albirea dentară" description="" sx={{ mt: { md: 2, xs: 0 } }} />

            <Grid container item xs={12} lg={12} textAlign="justify">
                <Grid item >
                    <MKTypography variant="body1" color="text" mb={1} mt={2} sx={{ display: { xs: "none", md: "inherit" } }}>
                        Dorința de a avea dinții mai albi se întâlnește foarte frecvent în rândul pacienților noștrii.
                        Ghidul ce urmează îți va da posibilitatea să iei o decizie informată în momentul în care optezi pentru
                        o metodă de albire.
                    </MKTypography>
                </Grid>
            </Grid>

            <MKBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 2, sm: 2, md: 2 }}
                mb={{ xs: 2, sm: 2, md: 2 }}

            >
                <CollapsableRow title="Pe scurt" description={peScurt} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Ce presupune, cum funcționează" description={cePresupune} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Tipuri de albire" description={tipuriDeAlbire} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Ce trebuie să știu înainte" description={stiuInainte} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Care este procedura" description={procedura} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Indicaţii şi restricţii în cazul albirii dentare efectuate acasă" description={albireaAcasa} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Ce trebuie să am în vedere după" description={dupaAlibire} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Combaterea sensibilității" description={sensibilitate} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
                <CollapsableRow title="Întrebări frecvente, Sfaturi utile" description={intrebariSfaturi} mt={2} titleVariant="body1" descriptionVariant="body1" descriptionFontSize="1.25rem" />
            </MKBox>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default AlbireaDentara;