import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";
import bgImage from "assets/images/services/treatments.jpg";

function Pedo() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Pedodonție" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>

                <Grid item textAlign="justify" >
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Vizita copiilor la cabinetul de stomatologie poate începe încă din primul an de viață.
                        Fiecare categorie de vârstă vine cu probleme specifice care pot să fie dentare sau de creștere și dezoltare ale oaselor maxilar.
                    </MKTypography>

                    <MKTypography variant="h4" color="text" mt={2} lineHeight="1.5">
                        <b>Prima vizită la medicul stomatolog</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Recomandăm ca primele vizite ale micilor pacienți la cabinetul de stomatologe să fie de acomodare.
                        Copilul vine cu părinții, se obișnuiește cu mediul și cu personalul, iar astfel o viitoare ședință de tratament vă decurge mult mai ușor.
                    </MKTypography>

                    <MKTypography variant="h4" color="text" mt={2} lineHeight="1.5">
                        <b>La ce trebuie să fie atenți părinții ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Erupția normală a dinților de lapte:</b>
                    </MKTypography>

                    <OptionItem text="Incisivii centrali la 6-12 luni" />
                    <OptionItem text="Incisivii laterali la 9-16 luni" />
                    <OptionItem text="Primii molari la 13-19 luni" />
                    <OptionItem text="Caninii la 16-23 luni" />
                    <OptionItem text="Molarii secundari la 22-33 luni" />
                    <MKTypography variant="body1" color="text" lineHeight="1.5">
                        pot exista variații de +/- câteva luni care sunt considerate normale, când se depășesc 6 luni de la vârsta normală de erupție este recomandată vizita la stomatolog
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Dinții temporari rămân pe aracadă până la erupția dinților permanenți de înlocuire.
                        Pierderea dinților temporari mai devreme de 2 ani de eruptia normală a celor permanenți
                        poate determina probleme de înghesuire și dezvoltare. Mai jos aveți vârstele până
                        la care se pot pierde dinții de lapte fară a creea probleme în dentiția permanentă.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Pierderea dinților de lapte, vârstă minimă:</b>
                    </MKTypography>
                    <OptionItem text="Incisivii centrali la 5-6  ani" />
                    <OptionItem text="Incisivii laterali la 6-7 ani" />
                    <OptionItem text="Primii molari la 8-9 ani" />
                    <OptionItem text="Caninii la 9-10 ani" />
                    <OptionItem text="Molarii secundari la 10-11 ani" />

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Obiceiuri vicioase</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Sunt considerate obiceiuri vicioase: sugerea degetului, interpunerea buzei inferioare între arcade,
                        interpunerea limbii în momentul în care copilul înghite, dacă persista peste vârstă de 2-3 ani.
                        Riscurile continuării acestor obiceiuri sunt înghesuiri dentare și probleme în creșterea și dezvoltarea normală a maxilarelor.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Erupția dinților permanenți</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Arcada superioară:
                    </MKTypography>
                    <OptionItem text="Incisivii centrali - 7-8 ani" />
                    <OptionItem text="Incisivii laterali - 8-9 ani" />
                    <OptionItem text="Caninii - 11-12 ani" />
                    <OptionItem text="Primii premolari - 10-11 ani" />
                    <OptionItem text="Premolarii secunzi - 10-12 ani" />
                    <OptionItem text="Primii molari - 6-7 ani" />
                    <OptionItem text="Molarii secunzi - 12-13 ani" />

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Arcada inferioară:
                    </MKTypography>
                    <OptionItem text="Incisivii centrali - 6-7 ani" />
                    <OptionItem text="Incisivii laterali - 7-8 ani" />
                    <OptionItem text="Caninii - 9-10 ani" />
                    <OptionItem text="Primii premolari - 10-12 ani" />
                    <OptionItem text="Premolarii secunzi - 11-12 ani" />
                    <OptionItem text="Primii molari - 6-7 ani" />
                    <OptionItem text="Molarii secunzi - 11-13 ani" />
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        La întârzierea cu mai mult de un an față de valorile prezentate este recomandată vizita la stomatolog.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default Pedo;