// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// fragments
import RotatingCard from "fragments/Cards/RotatingCard";
import RotatingCardFront from "fragments/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "fragments/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "fragments/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-front.jpeg";

// icons
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuizIcon from '@mui/icons-material/Quiz';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

function Arguments() {
    const frontCardTitle = "xDental";
    const frontCardDescription = "Locul în care totul se învârte în jurul pacientului. Nu pentru că trebuie, ci pentru că asta te va face sa zâmbești."
    const backCardTitle = "Un pacient informat face alegeri bune !"
    const backCardDescription = "Îți punem la dispoziție multe resurse, pe diverse teme, astfel încat să cunoști cât mai bine procedurile stomatologice";

    // arguments
    const argOne = {
        title: "Minim invaziv",
        description: "Folosim tehnici și echipamente de top pentru a beneficia de rezultate optime pe termen lung.",
        icon: <MedicalServicesIcon color="info" />
        //"medical_services"
    };
    const argTwo = {
        title: "Transparent",
        description: "Alegi informat planul de tratament care ți se potrivește și cunoști în avans toate costurile.",
        icon: <AssignmentIcon color="info" />
        //"assignment"
    };

    const argThree = {
        title: "Părerea ta contează",
        description: "Colectăm frecvent părerile pacienților noștri pentru a deveni mai buni și a ne adapta nevoilor lor.",
        icon: <QuizIcon color="info" />
        //quiz
    };

    const argFour = {
        title: "La fel și timpul tău",
        description: "Un timp de așteptare mai mare de 10 minute îți aduce un discount de 10%, iar sedințele de tratament sunt adaptate timpului tău disponibil.",
        icon: <MoreTimeIcon color="info" />
        //"more_time"
    };

    return (
        <MKBox component="section" mt={2} mb={6}>
            <Container>
                <Grid container item xs={11} spacing={1} alignItems="center" sx={{ mx: "auto" }}>
                    <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
                        <RotatingCard>
                            <RotatingCardFront
                                image={bgFront}
                                icon="touch_app"
                                title={
                                    <>
                                        {frontCardTitle}
                                    </>
                                }
                                description={frontCardDescription}
                            />
                            <RotatingCardBack
                                image={bgBack}
                                title={backCardTitle}
                                description={backCardDescription}
                                action={{
                                    type: "internal",
                                    route: "/util",
                                    label: "Informații utile",
                                }}
                            />
                        </RotatingCard>
                    </Grid>
                    <Grid item xs={12} lg={7} sx={{ ml: "auto", mt: { xl: 0, md: 2, xs: 2 } }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    icon={argOne.icon}
                                    title={argOne.title}
                                    description={argOne.description}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    icon={argTwo.icon}
                                    title={argTwo.title}
                                    description={argTwo.description}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ mt: { xs: 2, md: 4, xl: 4 } }}>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    icon={argThree.icon}
                                    title={argThree.title}
                                    description={argThree.description}
                                    action={{
                                        type: "external",
                                        route: "https://docs.google.com/forms/d/e/1FAIpQLSeakdmfpwod1SYPQ1Og_b39vcDvM41pyT4xk07TIFvjoRj6HA/viewform?usp=sf_link",
                                        label: "Formular Feedback"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DefaultInfoCard
                                    icon={argFour.icon}
                                    title={argFour.title}
                                    description={argFour.description}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Arguments;
