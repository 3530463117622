// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 React fragments
import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";
// Routes
import routes from "routes";

function CookiePolicy() {

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox component="section" >
                <Container>
                    <Grid container alignItems="center">

                        <Grid
                            item
                            xs={12}
                        >
                            <MKBox
                                bgColor="white"
                                borderRadius="xl"
                                shadow="lg"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                mt={{ xs: 20, sm: 18, md: 20 }}
                                mb={{ xs: 0, sm: 0, md: 0 }}
                            >
                                <MKBox
                                    variant="gradient"
                                    bgColor="info"
                                    coloredShadow="info"
                                    borderRadius="lg"
                                    p={2}
                                    mx={2}
                                    mt={-3}
                                >
                                    <MKTypography variant="h3" color="white">
                                        Politica privind modulele cookie
                                    </MKTypography>
                                </MKBox>

                                <MKBox py={6} sx={{ px: { xs: 2, sm: 4 } }} textAlign="justify">
                                    <MKTypography variant="body1" color="text" lineHeight="1.5">
                                        Această politică de cookie-uri explică ce sunt cookie-urile și cum le folosim pe site-ul nostru.
                                        Te invităm să parcurgi acest document pentru a înțelege ce tip de cookie-uri folosim,
                                        informațiile pe care le colectăm cu ajutorul modulelor cookie și modul în care sunt utilizate aceste informații.
                                        Poți să iți dai acceptul pentru utilizarea cookie-urilor și poți să-l revoci modificând setările de pe browser-ul tău,
                                        care iți permite să accepți sau să refuzi setarea tuturor sau a anumitor cookie-uri.
                                    </MKTypography>

                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        <b>Ce sunt cookie-urile ?</b>
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        Un „cookie” este un mic fișier text pe care un site îl plasează pe calculatorul sau pe dispozitivul
                                        dumneavoastră mobil atunci când îl accesați.
                                    </MKTypography>

                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        <b>Cum utilizăm cookie-urile ?</b>
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        De fiecare dată când vizitați site-ul <b>xdental.ro</b> veți fi invitat să acceptați sau să refuzați cookie-urile.
                                        Scopul este de a permite site-ului să vă rețină preferințele pentru o anumită perioadă de timp.
                                        Astfel, nu trebuie să reintroduceți aceste date atunci când navigați pe site în timpul aceleiași vizite.
                                        Cookie-urile pot fi utilizate, de asemenea, pentru a crea statistici anonimizate cu privire la experiența de navigare pe site.
                                    </MKTypography>

                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        <b>Ce tip de cookie-uri folosim ?</b>
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        Fisierele cookie pot fi sub forma de cookie-uri de sesiune (temporare) sau cookie-uri persistente.
                                        <br></br>
                                        <b>xdental.ro</b> folosește cookie-uri temporare - acestea sunt stocate temporar în dosarul "cookie" al
                                        browser-ului web, care le memorează până când utilizatorul iese de pe site sau închide fereastra browser-ului.
                                    </MKTypography>

                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        <b>Utilizăm următoarele cookie-uri:</b>
                                    </MKTypography>
                                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                                        <b>Facebook pixel</b>
                                        <br></br>
                                        Motivul utilizării: Acesta este un fragment de cod de pe site care iți permite să cuantifici,
                                        să optimizezi și să creezi grupuri de destinatari de campanii publicitare
                                        <MKTypography variant="body1" color="info" component={MuiLink} target="_blank"
                                            rel="noreferrer" href="https://www.facebook.com/business/help/471978536642445?id=1205376682832142" >
                                            <u>( Mai multe detalii aici )</u>
                                        </MKTypography>
                                    </MKTypography>
                                </MKBox>

                            </MKBox>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>

            <MKBox mt={6} >
                <CenteredFooter />
            </MKBox>
        </>
    );
}

export default CookiePolicy;
