import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function Orto() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Ortodonție" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>

                <Grid item textAlign="justify" >
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Ortodonția este ramura stomatologiei care se ocupă cu îndreptarea și corectarea poziției dinților și corectarea dezvoltării oaselor maxilare.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Pozițiile anormale ale dinților - dinții strâmbi - reprezintă o modificare de la ceea ce este considerată a fi poziția normala a dinților.
                        Anomalia de poziție a dinților este frecvent întâlnită la copii și adolescenți, iar netratată va persista și în viață de adult.
                        Poziția incorectă a elementelor dentare modifică ocluzia (modul în care pacientul mușcă), fapt ce afectează în timp
                        sănătatea dinților, a gingiilor și a articulației temporo-mandibulare. O masticație necorespunzătoare poate avea urmări nedorite și asupra digestiei.
                    </MKTypography>

                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default Orto;