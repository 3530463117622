import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function ImplantDentar() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Implantul dentar" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>

                <Grid item textAlign="justify" >
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Implantul dentar reprezintă cea mai bună soluție pentru înlocuirea unuia sau mai multor dinți lipsă.
                        În comparație, tratamentul alternativ este prepararea dinților vecini și realizarea unei lucrări protetice fixe,
                        care le vă scurta durata de viață a dinților existenți, sau realizarea unor proteze mobilizabile la care
                        confortul este incomparabil cu cel al unor lucrări fixe.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Ce este important de știut ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Momentul cel mai bun pentru inserarea unui implant este cât mai aproape de momentul extracției.
                        Când se pierde un dinte, osul începe să se retragă iar terapia împlântară vă fi cu atât mai dificilă cu cât așteptăm mai mult timp.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Planul de tratament diferă în funcție de dimensiunea osului rămas. Inserarea implantului poate poate fi urmată de realizarea lucrării
                        protetice (a dintelui) în aceeași ședință, sau se poate aștepta 6 luni până la etapa de realizare a coroanelor dentare.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        În cazul resorbțiilor osoase extinse poate fi nevoie de intervenții suplimentare de adiție de os sau de ridicare a sinusului maxilar.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Igienizarea va trebui să se facă riguros iar revenirea pentru controale regulate în cabinet este obligatorie pentru a asigura o durată de viață satisfăcătoare a implanturilor.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default ImplantDentar;