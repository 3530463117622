import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";

import bgImage from "assets/images/services/treatments.jpg";

function BoalaParo() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Boala Parodontală" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item >
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        Chiar dacă nu ai avut probleme serioase dentare până acum, nu ești ferit de această afecțiune care apare de multe ori la pacienți cu o
                        dantură în general sănătoasă. Boala parodontală poate trece neobservată până când ajunge la forme avansate, nu apar dureri foarte mari
                        și nu afectează masticația în forme de debut.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        <b>Boala parodontală</b> poate trece neobservată până când ajunge la forme avansate, nu apar dureri foarte mari și nu afectează masticația în
                        forme de debut. Reprezintă <b>boala țesuturilor</b> care înconjoară și susțin dintele – <b>osul și gingia</b>.  Prin urmare, ele sunt cele afectate,
                        dinții rămânând sănătoși. Patologia debutează cu afectarea gingiei prin inflamație - umflare, apariția unei colorații mai roșiatice și
                        prin sângerare. În continuare începe să fie afectat și osul care se retrage împreună cu gingia până când dintele nu vă mai avea suport,
                        se va mobiliza și în final vă cădea.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" textAlign="justify">
                        În primele faze, boala parodontală este 100% tratabilă dar dacă ajungem în etapele terminale, când dinții au mobilitate exagerată și osul
                        s-a retras aproape complet, singura soluție rămâne extracția. Vestea bună este că are de cele mai multe ori o evoluție lenta (ani de zile)
                        iar stomatologul o poate diagnostica încă de la debut. Netratată va duce întotdeauna la pierderea dinților.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Semne și simptome de debut:</b>
                        <OptionItem text="gingie inflamată" />
                        <OptionItem text="sângerare la periaj" />
                        <OptionItem text="retragera cu descoperirea rădăcinii dintelui" />
                        <OptionItem text="halena" />
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Tratament:</b>
                        <OptionItem text="igienizare în prima ședință și deprinderea unei tehnici de igienizare acasă corecte" />
                        <OptionItem text="evaluarea stadiului bolii parodontale" />
                        <OptionItem text="curățarea rădăcinilor și zonelor subgingivale în următoarele ședințe" />
                        <OptionItem text="reevaluare la 1 și 6 luni, în caz de nevoie se poate apela și la intervenții suplimentare." />
                        <br />Cum boala parodontală este o afecțiune inflamatorie, este recomandată eliminarea tuturor surselor de infecție: carii, lucrări neadaptate, resturi de rădăcini etc.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default BoalaParo;