import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalResponsiveCard from "fragments/Cards/HorizontalResponsiveCard";

import BasicStructure from "pages/BasicStructure";

import bgImage from "assets/images/services/treatments.jpg";
// Data
import data from "pages/Treatments/data/sections";


function Treatments() {
  const renderData = data.items.map(({ image, title, description, actions }, n) => (
    <Grid item xs={12} md={10} key={n}>
      <MKBox mb={1}>
        <HorizontalResponsiveCard
          image={image}
          name={title}
          description={description}
          imageMt='0'
          actions={actions}
        />
      </MKBox>
    </Grid>
  ));

  const contentOverTopImage = (<Container>
    <Grid
      container
      item
      xs={12}
      lg={8}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ mx: "auto", textAlign: "center" }}
    >
      <MKBox mb={8}>
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["3xl"],

            },
          })}

        >
          Tratamente
        </MKTypography>
      </MKBox>
      <MKBox >
        <MKTypography variant="h6" color="white" mt={8} mb={1}>
          Vă așteptăm și pe social media
        </MKTypography>

        <MKBox display="flex" justifyContent="center" alignItems="center" >
          <MKTypography component="a" variant="body1" color="white" href="https://www.linkedin.com/xdentaltm/" mr={3}>
            <i className="fab fa-linkedin" />
          </MKTypography>
          <MKTypography component="a" variant="body1" color="white" href="https://www.facebook.com/X-Dental-104933055444540/" mr={3}>
            <i className="fab fa-facebook" />
          </MKTypography>
          <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/xdentaltm/" mr={3}>
            <i className="fab fa-instagram" />
          </MKTypography>
        </MKBox>
      </MKBox>
    </Grid>
  </Container>);

  const bodyBgColor = "light";
  const body = (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {renderData}
      </Grid>
    </Container>
  );

  return (
    <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} bodyBgColor={bodyBgColor}>
    </BasicStructure>

  );
}

export default Treatments;
