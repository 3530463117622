import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";
import OptionItem from "fragments/OptionItem";

function IgienizareCoroaneLucrari() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 0 }}>
            <DefaultSectionTitle title="Igienizarea coroanelor și lucrărilor fixe" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12} >
                <Grid item sx={{ whiteSpace: "normal" }} textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <br />Trebuie să cuprindă toți pașii unei igienizări clasice: periaj, curățare interdentară, apă de gură.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Ce trebui să facem în plus ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        În cazul în care dintele este îmbrăcat complet, zona care prezintă risc este cea în imediata vecinătate a gingiei.
                        Astfel, trebuie să ne asigurăm că mijloacele noastre de igenizare ajung acolo
                        - Începem periajul cu periuță în contact cu gingia și de acolo avansăm spre dinte.
                        În zonele unde avem dinți lipsă este important să ajungem și între lucrare și gingie.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Mijloace speciale de igienizare a lucrărilor: </b>
                    </MKTypography>
                    <OptionItem mt={1} text="ață dentară speciala pt lucrări (Super Floss)" />
                    <OptionItem text="duș bucal – se poate folosi atât interdentar cât și pe sub lucrări" />
                </Grid>
            </Grid>
        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default IgienizareCoroaneLucrari;