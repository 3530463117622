import animal from "assets/images/blog/dog.jpg";
import economy from "assets/images/blog/economy.png";
import boalaParo from "assets/images/services/paro.jpg";
import albire from "assets/images/blog/albire.jpg";

const blogData = {
    description: "Blog presentation",
    items: [
        {
            image: albire,
            title: "Albirea dentară",
            description: "Beneficii, riscuri",
            icon: "",
            route: "/tratamente/albire",
            key: "albire"
        },
        {
            image: economy,
            title: "10 x banii garantat !",
            description: "Carie acum vs peste 1 an",
            icon: "",
            route: "/blog/multiplica-banii",
            key: "mult-banii"
        },
        {
            image: animal,
            title: "Animale știrbe ?",
            description: "Paralelă cu lumea animală",
            icon: "",
            route: "/blog/caria-la-animale",
            key: "fun-animal"
        },

        {
            image: boalaParo,
            title: "Boala parodontală",
            description: "Încet și sigur către pierderea dinților",
            icon: "",
            route: "/tratamente/boala-paro",
            key: "boala-paro"
        }]
};

export default blogData;