// @mui material components
import Grid from "@mui/material/Grid";
import FilledInfoCard from "fragments/Cards/InfoCards/FilledInfoCard";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuizIcon from '@mui/icons-material/Quiz';

function MadeSimple() {
    // arguments
    const argOne = {
        title: "Timp limitat ?",
        description: "Totul este adaptat fiecarui pacient. Programăm totul în avans, atât în cabinet cât și la tehnician. ",
        icon: <AssignmentIcon />
        //"assignment"
    };
    const argTwo = {
        title: "Durere ?",
        description: "Știm cât de mult afecteaza randamentul zilnic o durere dentară. De aceea, prioritizăm rezolvarea problemelor urgente ce provoacă disconfort sau se pot agrava.",
        icon: <MedicalServicesIcon />
        //"medical_services"
    };

    const argThree = {
        title: "Teamă ?",
        description: "Interacțiunea cu medicul este importantă, de aceea ne asigurăm ca ești confortabil înainte de a începe orice tratament.",
        icon: <QuizIcon />,
        //"quiz",
        action: {
            type: "external",
            route: "https://docs.google.com/forms/d/e/1FAIpQLScokgoOM5Im-jX0EeQ3tqHA5zA1wYGLKALI-EpJOygxfOUSEw/viewform?usp=sf_link",
            label: "Chestionar inițial"
        }
    };

    return (
        <Grid container spacing={2} sx={{ ml: "auto", mt: { xl: 6, md: 2, xs: 2 } }}>
            <Grid item xs={12} lg={4}>
                <FilledInfoCard
                    color="info"
                    variant="gradient"
                    icon={argThree.icon}
                    title={argThree.title}
                    description={argThree.description}
                    action={argThree.action}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <FilledInfoCard
                    color="info"
                    icon={argTwo.icon}
                    title={argTwo.title}
                    description={argTwo.description}
                    action={argTwo.action}
                />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ mt: { xs: -6, sm: -6, md: -4, lg: 0, xl: 0 } }}>
                <FilledInfoCard
                    color="info"
                    icon={argOne.icon}
                    title={argOne.title}
                    description={argOne.description}
                    action={argOne.action}
                />
            </Grid>
        </Grid >

    );

}

export default MadeSimple;