import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import bgImage from "assets/images/services/treatments.jpg";
import OptionItem from "fragments/OptionItem";

function DupaTratamentCanal() {
    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 8 }}>
            <DefaultSectionTitle title="La ce să mă aștept după un tratament de canal" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item sx={{ whiteSpace: "normal" }} textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5" >
                        Tratamentul de canal se realizează de cele mai multe ori când există o inflamație a nervului
                        sau o infecție la vârful rădăcinii - adică pe un teren deja inflamat și sensibil.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={1} lineHeight="1.5">
                        Tratamentul constă în curățarea și dezinfectarea canalului ce pot produce o iritație suplimentară. Astfel considerăm normale:
                    </MKTypography>
                    <OptionItem text="durere la masticație după tratament - este maximă în momentul în care trece anestezia și va dispărea pe parcursul următoarelor 2-3 zile" />
                    <OptionItem text="durere puternică neasociată cu masticația - în cazul în care exista infecție la nivelul rădăcinii există posibiliatatea unei acutizări, în cest caz comunicați medicului stomatolog acest lucru" />
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default DupaTratamentCanal;