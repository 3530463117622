import Grid from "@mui/material/Grid";

import DefaultReviewCard from "fragments/Cards/ReviewCards/DefaultReviewCard";
import SectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

function Testimonials() {

    return (
        <>
            <SectionTitle
                title="Ce spun pacienții noștri ?"
                description=""
                hashtag="# Best Patients" />

            <Grid container spacing={3} sx={{ mt: { lg: 8, md: 4, xs: 4 } }}>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultReviewCard

                        name="Adina U."
                        //date="August 2020"
                        review="Profesionalism la cotă maximă! Recomand cu toată încrederea ! Cel mai bun medic stomatolog la care am fost vreodată"
                        rating={5}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultReviewCard
                        color="info"
                        name="Alexandra G."
                        //date="Noiembrie 2020"
                        review="Medic deosebit, deoarece iți explică pas cu pas ceea ce face în desfășurarea actului medical, este om cald si calm, are răbdare, ascultă și sfătuiește pacienții în privința problemelor de natură stomatologică. Personal, recomand cu tot sufletul. Promit, veți fi pe mâini bune. Nu ezitați!"
                        rating={5}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DefaultReviewCard
                        name="Vicky U."
                        //date="August 2020"
                        review="Recomand cu tot sufletul ! Ne-a redat zâmbetul la 50 de ani, mie si sotului😘 Daruire și profesionalism cum nu am mai întalnit de mulți ani la un medic stomatolog"
                        rating={5}
                    />
                </Grid>

            </Grid>
        </>

    );
}

export default Testimonials;