import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";

import bgImage from "assets/images/services/treatments.jpg";

function MultiplicaBanii() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 4 }}>
            <DefaultSectionTitle title="Multiplică de 10 ori banii !" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>

                <Grid item textAlign="justify" >
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Pornim cu un pacient care știe că are o carie, fie a fost la un cotrol la medicul stomatolog,
                        fie a observat-o în oglinda sau simte o senibilitate la rece sau la cald, sigur ceva este în neregulă.
                    </MKTypography>
                    <MKTypography variant="h4" color="text" mt={2} lineHeight="1.5">
                        <b>Ce pot face ca pacient ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Prima variantă</b> - aleg sa tratez acum – asta ar însemna un tratament de 30 de minute la stomatolog și un total de plată de aproximativ 200 RON.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>A doua variantă</b> – amân vizita la stomatolog. Mă gândesc că mai poate aștepta, mi-e puțin teama să merg la stomatolog, poate nu este așa de rău,
                        mi se pare că nici sensibilitate nu mai am. Cel mai bine o mai las așa pentru următoarea săptămână ... Și a trecut un an și începe să mă doară.
                        <br /> Mai vorbim de o carie mică ce poate fi tratate într-o jumătate de ora ? – În niciun caz. Caria a ajuns probabil la nerv și a distrus jumătate din dinte.
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Tratament? </b>
                        <br />Începem cu tratamentul de canal – 450 RON
                        <br />Urmează un pivot de fibra de sticlă – 150 de RON
                        <br />Plomba coronară - 200 RON
                        <br />Coroana dentară 700 RON
                        <br />Total 1500 RON, vreo 3-4 ore de tratament, 5 vizite în cabinet și bonus vreo 2 nopți nedormite din cauza durerii.
                        <br />Cam atât poate să coste un an de amânare...dar 2, dar 10 ?
                        <br />Așa că gândește-te data viitoare că nu e scump și dureros la stomatolog, e scump și dureros să NU mergi la stomatolog.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default MultiplicaBanii;