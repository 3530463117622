// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import Switch from "@mui/material/Switch";

// Material Kit 2 React fragments
import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";
import Map from "fragments/Map";

import ValidatedInput from "fragments/ValidatedInput";
import ToastMessage from "fragments/ToastMessage";

// Routes
import routes from "routes";
import { constants } from 'util/constants'

//import bgImage from "assets/images/bg_contact.jpg";
import bgImage from "assets/images/DSC_8207.jpg";

// emailjs
import { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

function Contact2() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const formValid = nameState.isValid && emailState.isValid && phoneState.isValid;
    if (formValid) {
      emailjs.sendForm('wfMz-ctTy-s', 'yTJ-yp1-tm', form.current, 'wfMz-ctTy_yTJ-yp1')
        .then((result) => {
          setShowSuccess(true);
        }, (error) => {
          setShowError(true);
        });
    } else {
      setShowWarning(true);
    }
  };
  //malaescuradu93@gmail.com, andronachi.bianca@gmail.com
  useEffect(() => {
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const toggleSuccess = () => setShowSuccess(!showSuccess);
  const toggleError = () => setShowError(!showError);
  const toggleWarning = () => setShowWarning(!showWarning);

  const notEmptyRegex = new RegExp('.+');
  const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
  const phoneNumberRegex = new RegExp("[+0-9 ]{10,20}");
  const [emailState, setEmailState] = useState({ email: "", isValid: false });
  const onEmailStateChange = (email, isValid) => setEmailState({ email: email, isValid: isValid });

  const [nameState, setNameState] = useState({ name: "", isValid: false });
  const onNameStateChange = (name, isValid) => {
    setNameState({ name: name, isValid: isValid });
  }

  const [phoneState, setPhoneState] = useState({ phone: "", isValid: false });
  const onPhoneStateChange = (nr, isValid) => setPhoneState({ phone: nr, isValid: isValid });

  const arrowTypographyStyle = constants.arrowTypographyStyle;
  const textOpacity = 0.85;

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox component="section" py={{ xs: 16 }} >
        <ToastMessage message="Mulțumim ! Mesajul dumneavoastră a fost trimis cu succes." toastStyleName="success" show={showSuccess} toggleFunction={toggleSuccess} />
        <ToastMessage message="Din păcate mesajul nu poate fi trimis. Vă rugăm să ne contactați pe email sau telefon." toastStyleName="error" show={showError} toggleFunction={toggleError} />
        <ToastMessage message="Unele dintre valorile introduse nu sunt valide." toastStyleName="error" show={showWarning} toggleFunction={toggleWarning} />
        <Container>
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>

                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.7),
                        rgba(gradients.dark.state, 0.8)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* <Map location={constants.VICTORIEI_LOC} zoomLevel={18} /> */}
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Informații de contact
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={textOpacity} mb={3}>

                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="body2" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKBox display="flex" flexDirection="column">
                          <MKTypography
                            component="span"
                            variant="body2"
                            color="white"
                            opacity={textOpacity}
                            ml={2}
                            fontWeight="regular"
                          >
                            (+40) 741 231 875
                          </MKTypography>
                          <MKTypography
                            component="span"
                            variant="body2"
                            color="white"
                            opacity={textOpacity}
                            ml={2}
                            fontWeight="regular"
                          >
                            (+40) 726 157 870
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="body2" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="body2"
                          color="white"
                          opacity={textOpacity}
                          ml={2}
                          fontWeight="regular"
                        >
                          xdentaltm@gmail.com
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="body2" color="white">
                          <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="body2"
                          color="white"
                          opacity={textOpacity}
                          ml={2}
                          fontWeight="regular"
                        >
                          Piața Victoriei, Nr 1B, Timișoara, România
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" mt={0}>
                        <MKTypography ml={1}
                          component={MuiLink}
                          href="https://www.google.ro/maps/place/45%C2%B045'09.9%22N+21%C2%B013'33.2%22E/@45.752744,21.2247967,18z"
                          target="_blank"
                          rel="noreferrer"
                          variant="body2"
                          fontWeight="regular"
                          color="white"
                          opacity={textOpacity}
                          sx={arrowTypographyStyle}
                        >
                          Google Maps<Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </MKTypography>
                      </MKBox>
                      <MKBox mt={2} p={1}>
                        <MKTypography component="a" variant="text" color="white" href="https://www.linkedin.com/xdentaltm/" mr={3}>
                          <i className="fab fa-linkedin" />
                        </MKTypography>
                        <MKTypography component="a" variant="text" color="white" href="https://www.facebook.com/X-Dental-104933055444540/" mr={3}>
                          <i className="fab fa-facebook" />
                        </MKTypography>
                        <MKTypography component="a" variant="text" color="white" href="https://www.instagram.com/xdentaltm/" mr={3}>
                          <i className="fab fa-instagram" />
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>



                <Grid item xs={12} lg={7}>
                  <MKBox component="form" p={2} method="post" onSubmit={sendEmail} ref={form} >
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography variant="h2" mb={1}>
                        Solicitare Programare
                      </MKTypography>
                      <MKTypography variant="body1" color="text" mb={2}>
                        Vă rugăm să ne lăsați datele dvs și problema pe care o întâmpinați, iar în cel mai scurt timp veți fi contactat(ă)
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <ValidatedInput name="nume" label="Nume" helperText="" validationRegex={notEmptyRegex} changeResultCallback={onNameStateChange} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ValidatedInput name="email" label="Email" helperText="" type="email" validationRegex={emailRegex} changeResultCallback={onEmailStateChange} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ValidatedInput name="telefon" label="Telefon" helperText="" type="number" validationRegex={phoneNumberRegex} changeResultCallback={onPhoneStateChange} required />
                        </Grid>
                        <Grid item xs={12}>
                          <MKInput
                            variant="standard"
                            label="Cum vă putem ajuta ?"
                            name="mesaj"
                            placeholder="Scurtă descriere a problemelor întâmpinate ..."
                            InputLabelProps={{ shrink: true, style: { fontSize: "1.15rem" } }}
                            inputProps={{ style: { fontSize: "1rem" } }}
                            multiline
                            fullWidth
                            rows={6}
                          />
                        </Grid>

                        <Grid item xs={12} sx={{ ml: -1 }}>
                          <Switch id="flexSwitchCheckDefault" defaultChecked required />
                          <MKTypography
                            component="label"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            htmlFor="flexSwitchCheckDefault"
                            sx={{ userSelect: "none", cursor: "pointer" }}
                            fontSize="1rem"

                          >
                            {/* I agree to the{" "}
                            <MKTypography component="a" href="#" variant="button" fontWeight="regular">
                              <u>Terms and Conditions</u>
                            </MKTypography>
                            . */}
                            Sunt de acord să fiu contactat pe e-mail și/sau telefon
                          </MKTypography>
                        </Grid>
                        <Grid item xs={12} >
                          <MKButton variant="gradient" color="info" type="submit">
                            Trimite mesaj
                          </MKButton>
                        </Grid>
                      </Grid>

                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
          {/* sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}  */}
          <Grid container item sm={12} minHeight="65vh" >
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"

              sx={{ overflow: "hidden" }}
            >
              <Map location={constants.VICTORIEI_LOC} zoomLevel={18} />
            </MKBox>
          </Grid>
        </Container >

      </MKBox >



      <MKBox mt={-10} >
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default Contact2;