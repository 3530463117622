// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Images
import all from "assets/images/team/gallery/all.jpg";
import biaeva from "assets/images/team/gallery/be.jpg";
import biaradu from "assets/images/team/gallery/biaradu.jpg";
import biaralu from "assets/images/team/gallery/bra.jpg";
import radu from "assets/images/team/gallery/radu.jpg";
import radupacientbia from "assets/images/team/gallery/rpb.jpg";

function Gallery() {

    const h20 = "20vh";
    const h25 = "25vh";
    const h30 = "30vh";
    const h35 = "35vh";
    const minH = h35;
    return (
        <>

            <Grid container spacing={3} sx={{ minHeight: { xs: "200vh", sm: "200vh", md: "200vh", lg: "140vh" }, minWidth: "100%" }}>
                <Grid item xs={8} sm={6} md={6} lg={5} xl={3} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"

                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${biaradu})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
                <Grid item xs={8} sm={6} md={6} lg={5} xl={3} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${biaralu})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${radupacientbia})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
                <Grid item xs={8} sm={6} md={6} lg={5} xl={3} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${radu})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={5} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${all})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={5} xl={5} minHeight={minH}>
                    <MKBox
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        shadow="md"
                        sx={{
                            backgroundImage: `url(${biaeva})`,
                            backgroundSize: "cover",
                        }}
                    />
                </Grid>
            </Grid>

        </>
    );
}

export default Gallery;