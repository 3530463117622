import pedo from "assets/images/services/pedo.jpg";
import orto from "assets/images/services/orto.jpg";
import endo from "assets/images/services/s_endo.jpg";
import prote from "assets/images/services/prote.jpg";
import stoma_gen from "assets/images/services/s_stoma_gen.jpg";
import estetica from "assets/images/services/s_estetica.jpg";
import implanto from "assets/images/services/implanto.jpg";
import paro from "assets/images/services/paro.jpg";

//const actionLabel = "Citește mai mult";
const actionLabel = "Informații";
const treatments = {
    description: "Treatments",
    items: [
        {
            image: stoma_gen,
            title: "Stomatologie generală",
            description: "",
            actions: [
                { route: "/tratamente/obturatii", label: "Obturații (plombe)" },
                { route: "/tratamente/igienizare", label: "Igienizare profesională" }
            ]
        },
        {
            image: endo,
            title: "Endodonție",
            description: "Tratamente de canal pentru salvarea dintelui natural.",
            actions: [{ route: "/tratamente/endo", label: actionLabel }]
        },
        {
            image: estetica,
            title: "Estetică dentară",
            description: "Redarea aspectului similar dintelui natural.",
            actions: [{ route: "/tratamente/albire", label: "Albirea dentară" }]
        },
        {
            image: prote,
            title: "Protetică",
            description: "",
            actions: [
                { route: "/tratamente/proteze-totale", label: "Proteze totale" },
                { route: "/tratamente/coroane-lucrari-fixe", label: "Coroane și lucrări fixe" }
            ]
        },
        {
            image: implanto,
            title: "Implantologie / Chirurgie",
            description: "Interventii chirurgicale pentru îmbunațirea calitații vieții prin redobândirea functionalității și aspectului natural.",
            actions: [{ route: "/tratamente/chirurgie", label: actionLabel }]
        },
        {
            image: orto,
            title: "Ortodonție",
            description: "Aparate dentare pentru redobândirea funcționalității și esteticii dinților.",
            actions: [{ route: "/tratamente/orto", label: actionLabel }]
        },

        {
            image: paro,
            title: "Parodontologie",
            description: "Tratarea bolii parodontale prin tehnici minim invazive.",
            actions: [{ route: "/tratamente/boala-paro", label: actionLabel }]
        },

        {
            image: pedo,
            title: "Pedodonție",
            description: "Tratamente dentare și acomodarea copiilor cu vizitele la stomatolog.",
            actions: [{ route: "/tratamente/pedo", label: actionLabel }]
        },


    ]
};

export default treatments;