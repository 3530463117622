/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import ImageCard from "fragments/Cards/BlogCards/ImageBlogCardMod";

// Data
import data from "pages/Home/sections/data/blogData";

function BlogSlider() {
    const noAction = { type: 'none', route: 'none', label: 'none' };
    const renderData = data.items.map(({ image, title, description, icon, route, key }) => (
        <SwiperSlide key={key}>
            {/* <Link to={route}> */}
            <ImageCard image={image} name={key} title={title} description={description} action={{ type: "internal", route: route, label: "Deschide", color: "info" }} />
            {/* </Link> */}
        </SwiperSlide>

    ));

    return (
        <Container sx={{ mt: 8 }}>

            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    480: {
                        slidesPerView: 2,
                    },
                    780: {
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={30}
                centeredSlides={false}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                slidesPerView={"1"}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="blogSwiper"
            >
                {renderData}
            </Swiper>

        </Container>
    );
}

export default BlogSlider;
