const PriceItem = (name, price, description) => { return { name: name, price: price, description: description } }

const priceData = {
    description: "Price List",
    sections: [
        {
            title: "",
            items: [
                PriceItem("Consultație", "150", "Presupune evaluarea sanatatii cavitatii bucale.\nDesi aparent e „doar o consultatie” este, discutabil, cea mai importanta etapa din tratamentul stomatologic. In acest moment medicul depisteaza problemele de sanatate si propune planul de tratament.\n\nEste responsabilitatea stomatologului sa observe din timp atat eventuale problem dentare (carii, boala parodontala) cat si leziuni ale gingiei , ale mucoasei (eventuale infectii, chiar cancere).\n\nToate problemele dentare au un inceput, inceput care este foarte usor de tratat dar si de ratat. O consultatie bine efectuata poate sa fie cel mai important lucru ce il poti face pentru sanatatea ta orala pe langa igiena zilnica. "),
            ]
        },
        {
            title: "Igienizare",
            link: "Link igienizare corecta",
            items: [
                PriceItem("Completă (detartraj, periaj, airflow)", "250", "Se recomandă o dată la 1 an, iar pentru pacienții cu probleme parodontale sau depunere accentuată de tartru la 6 luni."),
                PriceItem("Completă - vizite regulate (6 luni)", "200", "Încurajăm și răsplătim igienizările regulate.\nMai puțin de lucru si pentru noi, mai multă siguranță pentru tine.\n2 igienizări pe an se vor face la prețul de 150 lei per igienizare"),
                PriceItem("Detartraj per dinte", "35", "Folosind o ansă metalică ce actioneză prin vibrații ultrasonice, cu rolul de a îndepărta depunerile dure de pe dinți care nu mai pot fi îndepărtate cu periuța."),
                PriceItem("Periaj + air-flow", "200", "Air folow - presupune aplicarea unui jet de particule de bicarbonat și apă pe suprafața dintelui pentru îndepărtarea microdepunerilor și petelor.\nAceastă variantă este preferată cand pacientul nu prezintă depuneri de tartru."),
                PriceItem("Fluorizare", "120", "Ajută la remineralizarea dinților și scade riscul de apariție a cariilor"),
            ]
        },
        {
            title: "Albire",
            link: "vezi ghid albire",
            items: [
                PriceItem("Lampa ZOOM", "1500", "Se folosește gel de albire activat de o lampă specială.\n\nAvantaje - efect mai rapid și sensibilitate redusă."),
                PriceItem("Peroxid", "600", "Se folosește gel simplu."),
                PriceItem("Gutiere", "250/arcada", "Folosite pentru aplicarea gelului de albire acasă")
            ]
        },
        {
            title: "Odontoterapie (obturații / plombe)",
            link: "",
            items: [
                PriceItem("Obturație Mica", "250", "Implică o suprafață dentară iar caria este localizată doar în smalț"),
                PriceItem("Obturație Medie", "300", "Cuprinde 2 fețe ale dintelui"),
                PriceItem("Obturație Mare", "350", "Cuprinde cel puțin 2 fețe ale dintelui iar caria ajunge aproape de nerv - este nevoie de un strat aditional de protecție pentru nerv"),
                PriceItem("Obturație Frontală", "320", "Obturații cu cerințe estetice ridicate"),
                PriceItem("Fațetare Compozit", "400", "Plomba se aplică pe întreaga suprafață vizibilă a dintelui pentru a schimba forma și aspectul dintelui"),
                PriceItem("Obturație CIS", "150", "Este folosită ca obturație provizorie de lungă durată"),
                PriceItem("Coafaj Indirect", "70", "Caria este foarte aproape de nerv, există riscul expunerii acestuia"),
                PriceItem("Coafaj Direct", "100", "Folosit la tineri în cazul în care caria expune nervul și este nevoie de protecția suplimentară a acestuia"),
            ]
        },
        {
            title: "Endodonție (tratamente de canal)",
            link: "",
            items: [
                PriceItem("Devitalizare (urgență)", "150", "Scopul tratamentului este calmarea durerii prin îndepărtarea nervului.\n\nOdată ce durerea a trecut se va continua tratamentul"),
                PriceItem("Dinte monoradicular", "450", "Dinții cu un singur canal sunt în mod normal: incisivii, premolarii, caninii (excepție primul premolar superior)"),
                PriceItem("Dinte pluriradicular", "700", "Dinții cu mai multe canale sunt molarii,(cu 3-4 canale) și premolarul 1 superior (2 canale)"),
                PriceItem("Molar de minte", "800", "Poate sa aiba 2 sau 3 canale în mod normal.\n\nAcesul dificil și forma curbata a radacinilor îngreunează de obicei tratamentul"),
                PriceItem("Retratament mono", "600", "Se realizează când tratamentul de canal este infiltrat sau incomplet și cand există semne de inflamație sau durere la un dinte tratat în antecedente pe canal"),
                PriceItem("Retratament pluri", "900", "Se realizează când tratamentul de canal este infiltrat sau incomplet și cand există semne de inflamație sau durere la un dinte tratat în antecedente pe canal"),
                PriceItem("Medicamentație interimară", "100", "Se aplica în cazuri în care tratamentul endodontic nu se poate finaliza în 1 sau 2 ședinte și exista semne de inflamație și infecție la nivelul rădăcinii dintelui"),
                PriceItem("Pivot fibra de sticlă", "180", "Aplicarea se recomandă când dintele este tratat endodontic și mai mult de 50 la sută din țesut este afectat."),
                PriceItem("Tratament sub microscop", "100", "Microscopul se utilizează în situații în care tratamentul de canal prezintă o dificultate ridicată"),
                PriceItem("Albire internă", "200", "Se realizează când un dinte are colorație mai închisă față de dinții vecini, de obicei la un dinte care are tratament endodontic."),
            ]
        },
        {
            title: "Chirurgie",
            link: "",
            items: [
                PriceItem("Extracție Dinte monoradicular", "250", "Dinții cu o singură rădăcina sunt incisivii, caninii, și premolarii. Dar pot exista excepții"),
                PriceItem("Extracție Dinte pluriradicular", "350", "Dinții cu mai multe rădăcini sunt premolarul prim superior, și molarii"),
                PriceItem("Extracție Dinte parodontotic", "180", "Dinte cu mobilitate mare la care extracția este facilă"),
                PriceItem("Extracție Molar de minte", "400 - 450", "Prețul diferă în funție de dificultatea extracției- ex 550 lei este prețul când dintele se află complet in os"),
                PriceItem("Drenaj abces", "200", "Abcesul se manifestă prin dureri, inflamația gingiei și chiar a feței.\n\nSe pot forma colecții de puroi care necesită elibreare (drenare)"),
                PriceItem("Gingivectomie", "150", "Constă în îndepartarea gingiei local la nivelui dintelui din motive estetice sau dacă interferă cu tratarea unei carii sau realizarea unei lucrări"),
                PriceItem("Alungire corono-radiculară", "300", "Se realizează când este nevoie de o descoperire a dintelui mai mare decât în cazul gingivectomieia, pe langă îndepartarea gingiei se îndepartează și puțin din os"),
                PriceItem("Regularizare creastă", "250 - 550", "De obicei se realizează după extracții multiple când relieful osos este denivelat"),
                PriceItem("Decapușonare", "200", "În cazul molarului de minte sau la copii când dintele rămâne sub gingie, iar aceasta trebuie îndepartată"),
                PriceItem("Rezecție apicală", "500", "După ce tratamentul de canal eșuează, ca ultima soluție înainte de extracție apelăm la rezecție apicală"),
            ]
        },
        {
            title: "Implantologie",
            link: "",
            items: [
                PriceItem("Implant", "550 €", ""),
                PriceItem("Aditie os", "350 € / gram", ""),
                PriceItem("Bont protetic + Coroana metalo-ceramica", "400 € / implant", ""),
                PriceItem("Bont protetic + Coroana zirconiu", "650 € / implant", ""),
            ]
        },
        {
            title: "Protetică fixă",
            link: "",
            items: [
                PriceItem("Ablație", "80", "Presupune îndepartarea lucrărilor vechi.\n\nPrețul se calculează pe îmbrăcat"),
                PriceItem("Coroană provizorie directă", "150", "Reprezintă coroana dentară realizată pe loc în cabinet.\n\nSe utilizează pentru o perioadă scurtă"),
                PriceItem("Coroană provizorie indirectă", "250", "Este realizată la tehnician, poate fi folosită o perioadă mai îndelungată."),
                PriceItem("Coroană metalo-ceramică ONE", "150 €", "Metalul este complet acoperit de ceramica dar ceramica este într-o singură nuanță, nu imită pigmentările naturale ale dintelui."),
                PriceItem("Coroană metalo-ceramică individualizată", "170 €", ""),
                PriceItem("Coroană metalo-ceramică parțială", "150 €", "Ceramica acoperă metalul doar în zonele de vizibilitate maximă."),
                PriceItem("Coroană oxid de zirconiu", "210 €", "Coroana cu o biocompatibilitate crescută, estetică și cu rezistență foarte mare."),
                PriceItem("Coroană oxid de zirconiu plus ceramică", "260 €", "Indicat în zonele frontale, ceramica aplicată îmbunatățeste estetica."),
                PriceItem("Coroană ceramică E-MAX", "350 €", "Indicat pentru cel mai bun aspect estetic."),
                PriceItem("Mock-up / Wax-up", "25 € / 15 €", "Se realizează pentru testarea formei viitoarelor lucrări pentru eventuale ajustări înainte de realizarea lucrărilor finale."),
                PriceItem("Inlay - onlay", "300 - 350 €", "Este o variantă de restaurare a dinților care permite păstrarea unei cantități maxime de smalț."),
                PriceItem("Fațeta", "350 €", "Acoperă doar suprafața vizibilă a dintelui.\n\nÎmbunatătește radical aspectul dinților."),
            ]
        },
        {
            title: "Protetică mobilă si mobilizabilă",
            link: "",
            items: [
                PriceItem("Croșete turnate", "3500", "Se realizează în cazul în care s-au pierdut prea mulți dinți pentru a se putea realiza o proteza fixă.\n\nProteza se sprijină pe dinții restanți și pe gingie."),
                PriceItem("Cu sisteme speciale", "4000", "Se realizează în cazul în care s-au pierdut prea mulți dinți pentru a se putea realiza o proteză fixă.\n\nProteza se sprijină pe dinții restanți și pe gingie."),
                PriceItem("Proteză totală clasică", "1800", "Se realizează când au mai ramas mai puțin de 4 dinți pe arcadă."),
                PriceItem("Proteză totală flexibilă", "2300", "Se realizează când au mai ramas mai puțin de 4 dinți pe arcadă."),
                PriceItem("Căptușire", "250", "Cu timpul proteza totală își pierde din stabilitate dând senzația că devine largă. Pentru a rezolva această problemă se realizează căptușirea protezei."),
                PriceItem("Reparație", "200", "Proteza se poate crăpî sau fractura în cazul în care este scapată pe jos. Vestea bună este că se poate repara ușor."),
            ]
        },
        {
            title: "Parodontologie",
            link: "",
            items: [
                PriceItem("Evaluare parodontală", "150", "Se masoară și se notează pe fișa nivelul gingiei, retractia osoasă, sângerarea gingivală pentru a stabili diagnosticul și pentru a verifica eficiența tratamentului."),
                PriceItem("FMD în câmp închis", "200/dinte", "FMD - Full Mouth Disinfection.\n\nPresupune curațarea și dezinfectarea rădăcinii dintelui."),
                PriceItem("FMD în câmp deschis", "350/dinte", "FMD - Full Mouth Disinfection.\n\nPresupune îndepartarea gingiei pentru a vizualiza rădăcina dentară urmată de curățarea și dezinfectarea acesteia."),
                PriceItem("Imobilizare fibră de sticlă", "300", "Presupune solidarizarea dinților vecini între ei.\n\nSe realizează în cazul dinților cu mobilitate mare."),
            ]
        },
        {
            title: "Pedodonție",
            link: "",
            items: [
                PriceItem("Extracție dinte temporar", "120", "Este indicat să se efectueze cât mai aproape de erupția dintelui succesor permanent."),
                PriceItem("Periaj + fluorizare", "180", "Periajul îndepartează placa bacteriană iar fluorizarea oferă protecție împotriva apariției cariilor."),
                PriceItem("Sigilare", "150", "Este recomandată sigilarea dinților pemanenți în primul an de la eruptie.\n\nPresupune astuparea șanțurilor și fosetelor pentru diminuarea riscului de apariție a cariei."),
                PriceItem("Drenaj abces", "140", "În cazul cariilor mari pe dinții temporari pot să apară infecții localizate la nivelul gingiei în dreptul dintelui afectat. Este necasară deschiderea dintelui afectat."),
                PriceItem("Pansament calmant", "140", "În cazul în care caria ajunge la nervul dintelui temporar și începe să doară se intervine pentru desensibilizarea dintelui."),
                PriceItem("Tratament endodontic", "300", "Nervul dintelui temporar afectat de carie este îndepartat."),
                PriceItem("Medicație interimară", "70", ""),
                PriceItem("Obturație compozit", "200", "Se realizează de obicei pe dinții permanenți."),
                PriceItem("Obturație CIS", "120", "Se realizează de obicei pe dinții temporari."),
                PriceItem("Menținător de spațiu", "300", "Este indicată aplicarea unui menținator de spațiu în cazul pierderii dinților temporari cu mai mult de 2 ani înainte de erupția dintelui permanent."),
                PriceItem("Frenectomie", "300", "Este indicată în cazul frenurilor bucale mari, care interferă cu armonia dentară sau cu mișcările funcționale."),
            ]
        },
        {
            title: "Altele",
            link: "",
            items: [
                PriceItem("Gutieră bruxism", "250", "Este o folie de plastic care este adaptata dinților fiecarui pacient.\n\nAre rolul de a proteja dinții, se poartă mai ales în timpul nopții.\n\n"),
                PriceItem("Gutieră albire", "250", ""),
                PriceItem("Gutieră contenție", "250", "Este o folie de plastic care este adaptată dinților fiecarui pacient.\n\nEste indicată post tratament ortodontic."),
                PriceItem("Aplicare desensibilizant", "70", "Recomandat în cazul sensibilității dentare persistente."),
                PriceItem("Tratamentul aftelor bucale", "70", ""),
            ]
        },
    ]
};

export default priceData;