// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React fragments
import DefaultNavbar from "fragments/Navbars/DefaultNavbar";
import CenteredFooter from "fragments/Footers/CenteredFooter";

// Routes
import routes from "routes";

function BasicStructure({ topImage, contentOverTopImage, body, bodyBgColor }) {
    const bodyContent = bodyBgColor === "light" ? (<Card
        sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { light }, functions: { rgba } }) => rgba(light.main, 0.7),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
    >
        {body}
        <Container sx={{ mt: 8 }}>
        </Container>
    </Card>) : (<Card
        sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.7),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
    >

        {body}
        <Container sx={{ mt: 8 }}>
        </Container>
    </Card>);

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${topImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                {contentOverTopImage}
            </MKBox>

            {/* BODY */}
            {bodyContent}
            {/* /BODY */}


            <MKBox pt={2} px={1} >
                <CenteredFooter />
            </MKBox>
        </>
    );
}

export default BasicStructure;
