import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import BasicStructure from "pages/BasicStructure";
import DefaultSectionTitle from "fragments/SectionTitles/DefaultSectionTitle";
import OptionItem from "fragments/OptionItem";
import bgImage from "assets/images/services/treatments.jpg";

function TratamentCanal() {

    const contentOverTopImage = "";
    const body = (
        <Container sx={{ mt: 6, mb: 2 }}>
            <DefaultSectionTitle title="Tratamentul de canal" description="" sx={{ mt: { md: 2, xs: 0 } }} />
            <Grid container item xs={12}>
                <Grid item textAlign="justify">
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Este necesar atunci când:
                        <OptionItem text="caria dentară pătrunde adânc în dinte, până la nivelul camerei pulpare (locul unde se află nervul)" />
                        <OptionItem text="se deschide accidental camera pulpară în timp ce caria dentară este tratată" />
                        <OptionItem text="dintele suferă o fractură cu implicarea camerei pulpare" />
                        <OptionItem text="în scop protetic, atunci când se dorește realizarea unei coroane dentare fizionomice" />
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Cum știu că este necesar tratamentul de canal ?</b>
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        Simptomele care sugerează posibilitatea unui tratament de canal sunt următoarele:
                        <OptionItem text="sensibilitate crescută la cald sau rece pe un anumit dinte" />
                        <OptionItem text="durere" />
                        <OptionItem text="pigmentarea dintelui într-o nuanță cenușie" />
                        <OptionItem text="prezenta unei umflături pe gingie în dreptul unui dinte" />
                        <br />Cu toate acestea, sunt situații care nu prezintă nici un fel de simptomatologie în acel moment și care pot fi depistate doar de medicul stomatolog în urma unui examen clinic și a unei radiografii.
                    </MKTypography>

                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        <b>Cum se realizează tratamentul de canal ?</b>
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mt={2} lineHeight="1.5">
                        După ce medicul stomatolog a stabilit că dintelui în cauza i se va realiza un tratament de canal - se trece la anestezierea pacientului, apoi cu o freză se realizează o deschidere în dinte, loc pe unde se va pătrunde pentru curățarea acestuia mecanic, cu un instrumentar diversificat, și chimic cu mai multe soluții. După tratamentele mecanice și chimice aplicate canalelor dentare urmează sigilarea acestora cu pastă pentru obturații de canal și gutapercă.
                        <br /><br />
                        De obicei aceste proceduri sunt realizare în ședințe de câte o oră sub anestezie locală. Anestezia este obligatorie în cazul tratamentelor endodontice pentru a asigura atât confortul pacientului cât și premizele unui tratament efectuat corect.
                    </MKTypography>
                </Grid>
            </Grid>

        </Container>
    );

    return (
        <BasicStructure topImage={bgImage} contentOverTopImage={contentOverTopImage} body={body} >
        </BasicStructure>
    );
}

export default TratamentCanal;